// Code generated by graphql-codegen. DO NOT EDIT.
import type * as Types from './types';

import { gql } from '@apollo/client';
import { ListSpeakingPartSelection, DetailSpeakingPartSelection, DetailSpeakingCheckPointSelection, DetailWritingCheckPointSelection, DetailWritingPartSelection, DetailSchoolSelection, SpeakingCheckpointPart, ListCheckPointSelection, DetailCheckPointSelection, ListWritingPartSelection } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTeacherProfileVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.TeacherProfilesBoolExp>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetTeacherProfile = { teacher_profiles: Array<{ id: string, display_name: string, first_name: string, user: { id: string, email?: string | null, phone_number?: string | null, user_name?: string | null } }> };

export type ListOrdersVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.VnpayPaymentsBoolExp>;
  order_by?: Types.InputMaybe<Array<Types.VnpayPaymentsOrderBy> | Types.VnpayPaymentsOrderBy>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type ListOrders = { vnpay_payments: Array<{ created_at: string, email?: string | null, error_message?: string | null, flyer_active_code?: string | null, txn_ref: string, user_name?: string | null, vnp_amount: number, package_active?: string | null, id: string, vnp_create_date: string, status?: string | null, phone_number?: string | null, active_code_users: Array<{ activated_at: string, code: string, id: string, new_user_id?: string | null, user_id?: string | null }> }> };

export type CountOrdersVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.VnpayPaymentsBoolExp>;
}>;


export type CountOrders = { vnpay_payments_aggregate: { aggregate?: { count: number } | null } };

export type ListCustomDomainsVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.CustomDomainBoolExp>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  order_by?: Types.InputMaybe<Array<Types.CustomDomainOrderBy> | Types.CustomDomainOrderBy>;
}>;


export type ListCustomDomains = { custom_domain: Array<{ created_at: string, domain_name?: string | null, id: string, status: string, user_email?: string | null, rejected_domain_name?: string | null, user_phone_number?: string | null, show_diamond: boolean, users: Array<{ id: string, subscription_expire_at?: string | null }>, schools: Array<{ classes: Array<{ class_students: Array<{ learner_profile?: { user_attempts_aggregate: { aggregate?: { count: number } | null } } | null }> }> }>, teacher_profile?: { display_name: string, first_name: string, id: string, last_name?: string | null, user: { email?: string | null, id: string, phone_number?: string | null, user_name?: string | null } } | null }> };

export type CountCustomDomainsVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.CustomDomainBoolExp>;
}>;


export type CountCustomDomains = { custom_domain_aggregate: { aggregate?: { count: number } | null } };

export type GetTestLevelsVariables = Types.Exact<{ [key: string]: never; }>;


export type GetTestLevels = { testLevels: Array<{ id: string, image_url?: string | null, levels: Record<string, unknown> | Array<unknown>, slug: string, name: string, order: number, color: string, updated_at: string, created_at: string, description?: string | null, type: string, description_en?: string | null, exam_settings: Array<{ execution_time: string, exam_type: Types.ExamTypesEnum }> }> };

export type GetSpeakingPartsCountVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.SpeakingPartsBoolExp>;
}>;


export type GetSpeakingPartsCount = { speakingPartsAggregate: { aggregate?: { count: number } | null } };

export type GetSpeakingPartsVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.SpeakingPartsBoolExp>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetSpeakingParts = { speakingParts: Array<{ id: string, name: string, title: string, partType: Types.SpeakingPartTypesEnum, position: number, createdAt: string, updatedAt: string, time?: string | null, userCreated?: { email?: string | null } | null, userUpdated?: { email?: string | null } | null, questions: Array<{ id: string, answerImageId?: string | null, groupPosition?: number | null, position: number, keys?: Record<string, unknown> | Array<unknown> | null, numberCorrectKey: number, answerPlaceholderId?: string | null, recommendSentences?: Record<string, unknown> | Array<unknown> | null, maxScore: number, audio?: { id: string, subtitle: string } | null, images: Array<{ position: number, image: { id: string } }> }> }> };

export type GetSpeakingPartVariables = Types.Exact<{
  id: Types.Scalars['bigint']['input'];
}>;


export type GetSpeakingPart = { speakingPart?: { id: string, name: string, title: string, partType: Types.SpeakingPartTypesEnum, position: number, createdAt: string, updatedAt: string, time?: string | null, findDifferences?: { id: string, candidateImage: { id: string }, examinerImage: { id: string } } | null, infoExchange?: { id: string, candidateSheetTitle: string, examinerSheetTitle: string, contentImage: { id: string }, candidateSheets: Array<{ id: string, title: string, value: string, position: number }>, examinerSheets: Array<{ id: string, title: string, value: string, position: number }> } | null, picStoryImages: Array<{ position: number, image: { id: string } }>, findObject?: { id: string, backgroundImage?: { id: string } | null, objectImages: Array<{ width: string, height: string, left: string, top: string, position: number, image: { id: string } }> } | null, moveObject?: { id: string, backgroundImage: { id: string }, objectImages: Array<{ position: number, image: { id: string } }>, objectPlaceholders: Array<{ id: string, imageId?: string | null, width: string, height: string, left: string, top: string, position: number }> } | null, sceneCard?: { id: string, backgroundImage: { id: string }, points: Array<{ id: string, left: string, top: string, position: number }> } | null, objectCardImages: Array<{ position: number, image: { id: string } }>, userCreated?: { email?: string | null } | null, userUpdated?: { email?: string | null } | null, questions: Array<{ id: string, answerImageId?: string | null, groupPosition?: number | null, position: number, keys?: Record<string, unknown> | Array<unknown> | null, numberCorrectKey: number, answerPlaceholderId?: string | null, recommendSentences?: Record<string, unknown> | Array<unknown> | null, maxScore: number, audio?: { id: string, subtitle: string } | null, images: Array<{ position: number, image: { id: string } }> }> } | null };

export type GetSpeakingCheckPointsCountVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.SpeakingCheckPointsBoolExp>;
}>;


export type GetSpeakingCheckPointsCount = { speakingCheckPointsAggregate: { aggregate?: { count: number } | null } };

export type GetSpeakingCheckPointsVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.SpeakingCheckPointsBoolExp>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetSpeakingCheckPoints = { speakingCheckPoints: Array<{ checkPointId: string, checkPoint: { id: string, name: string, updated_by?: string | null, created_by?: string | null, time?: string | null, type: string, domains?: Record<string, unknown> | Array<unknown> | null, level: Record<string, unknown> | Array<unknown>, nameEn?: string | null, isPremium: boolean, createdAt: string, updatedAt: string, testCount: string, maxScore?: number | null, maxGrade: number, userCreated?: { email?: string | null } | null, userUpdated?: { email?: string | null } | null }, testLevel?: { id: string, name: string } | null }> };

export type GetSpeakingCheckPointVariables = Types.Exact<{
  checkPointId: Types.Scalars['uuid']['input'];
}>;


export type GetSpeakingCheckPoint = { speakingCheckPoint?: { checkPointId: string, position: number, checkPoint: { description?: string | null, type: string, domains?: Record<string, unknown> | Array<unknown> | null, level: Record<string, unknown> | Array<unknown>, skills?: Record<string, unknown> | Array<unknown> | null, image_url?: string | null, time?: string | null, order: number, diamond?: number | null, id: string, name: string, updated_by?: string | null, created_by?: string | null, descriptionEn?: string | null, allowReviewAnswers: boolean, attemptsAllowed: number, testCount: string, nameEn?: string | null, isPremium: boolean, createdAt: string, updatedAt: string, maxScore?: number | null, maxGrade: number, check_point_speaking_parts: Array<{ check_point_id: string, order: number, position: number, speaking_part_id: string, speakingPart?: { id: string, partType: Types.SpeakingPartTypesEnum, name: string, time?: string | null, questions: Array<{ maxScore: number, id: string }> } | null }>, userCreated?: { email?: string | null } | null, userUpdated?: { email?: string | null } | null }, speakingParts: Array<{ id: string, name: string, title: string, partType: Types.SpeakingPartTypesEnum, position: number, createdAt: string, updatedAt: string, time?: string | null, userCreated?: { email?: string | null } | null, userUpdated?: { email?: string | null } | null, questions: Array<{ id: string, answerImageId?: string | null, groupPosition?: number | null, position: number, keys?: Record<string, unknown> | Array<unknown> | null, numberCorrectKey: number, answerPlaceholderId?: string | null, recommendSentences?: Record<string, unknown> | Array<unknown> | null, maxScore: number, audio?: { id: string, subtitle: string } | null, images: Array<{ position: number, image: { id: string } }> }> }>, testLevel?: { id: string, name: string, slug: string } | null } | null };

export type GetWritingCheckPointsCountVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.WritingCheckPointsBoolExp>;
}>;


export type GetWritingCheckPointsCount = { writingCheckPointsAggregate: { aggregate?: { count: number } | null } };

export type GetWritingCheckPointsVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.WritingCheckPointsBoolExp>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetWritingCheckPoints = { writingCheckPoints: Array<{ checkPointId: string, checkPoint: { id: string, name: string, updated_by?: string | null, created_by?: string | null, time?: string | null, type: string, domains?: Record<string, unknown> | Array<unknown> | null, level: Record<string, unknown> | Array<unknown>, nameEn?: string | null, isPremium: boolean, createdAt: string, updatedAt: string, testCount: string, maxScore?: number | null, maxGrade: number, userCreated?: { email?: string | null } | null, userUpdated?: { email?: string | null } | null }, testLevel?: { id: string, name: string } | null }> };

export type GetWritingCheckPointVariables = Types.Exact<{
  checkPointId: Types.Scalars['uuid']['input'];
}>;


export type GetWritingCheckPoint = { writingCheckPoint?: { checkPointId: string, position: number, checkPoint: { description?: string | null, type: string, domains?: Record<string, unknown> | Array<unknown> | null, level: Record<string, unknown> | Array<unknown>, skills?: Record<string, unknown> | Array<unknown> | null, image_url?: string | null, time?: string | null, order: number, diamond?: number | null, id: string, name: string, updated_by?: string | null, created_by?: string | null, descriptionEn?: string | null, allowReviewAnswers: boolean, attemptsAllowed: number, testCount: string, nameEn?: string | null, isPremium: boolean, createdAt: string, updatedAt: string, maxScore?: number | null, maxGrade: number, check_point_speaking_parts: Array<{ check_point_id: string, order: number, position: number, speaking_part_id: string, speakingPart?: { id: string, partType: Types.SpeakingPartTypesEnum, name: string, time?: string | null, questions: Array<{ maxScore: number, id: string }> } | null }>, userCreated?: { email?: string | null } | null, userUpdated?: { email?: string | null } | null }, writingParts: Array<{ id: string, name: string, title?: string | null, position: number, createdAt: string, updatedAt: string, time?: string | null, userCreated?: { email?: string | null } | null, userUpdated?: { email?: string | null } | null }>, testLevel?: { id: string, name: string } | null } | null };

export type GetCheckPointVariables = Types.Exact<{
  checkPointId: Types.Scalars['uuid']['input'];
}>;


export type GetCheckPoint = { check_points_by_pk?: { description?: string | null, type: string, domains?: Record<string, unknown> | Array<unknown> | null, level: Record<string, unknown> | Array<unknown>, skills?: Record<string, unknown> | Array<unknown> | null, image_url?: string | null, time?: string | null, order: number, diamond?: number | null, id: string, name: string, updated_by?: string | null, created_by?: string | null, descriptionEn?: string | null, allowReviewAnswers: boolean, attemptsAllowed: number, testCount: string, nameEn?: string | null, isPremium: boolean, createdAt: string, updatedAt: string, maxScore?: number | null, maxGrade: number, check_point_speaking_parts: Array<{ check_point_id: string, order: number, position: number, speaking_part_id: string, speakingPart?: { id: string, partType: Types.SpeakingPartTypesEnum, name: string, time?: string | null, questions: Array<{ maxScore: number, id: string }> } | null }>, userCreated?: { email?: string | null } | null, userUpdated?: { email?: string | null } | null } | null };

export type GetWritingPartsCountVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.WritingPartsBoolExp>;
}>;


export type GetWritingPartsCount = { writingPartsAggregate: { aggregate?: { count: number } | null } };

export type GetWritingPartsVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.WritingPartsBoolExp>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetWritingParts = { writingParts: Array<{ id: string, name: string, title?: string | null, position: number, createdAt: string, updatedAt: string, time?: string | null, userCreated?: { email?: string | null } | null, userUpdated?: { email?: string | null } | null }> };

export type GetWritingPartVariables = Types.Exact<{
  id: Types.Scalars['bigint']['input'];
}>;


export type GetWritingPart = { writingPart?: { id: string, name: string, title?: string | null, position: number, createdAt: string, updatedAt: string, time?: string | null, images: Array<{ position: number, image: { id: string, blurHash?: string | null } }>, userCreated?: { email?: string | null } | null, userUpdated?: { email?: string | null } | null } | null };

export type GetSchoolsVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.SchoolsBoolExp>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetSchools = { schools: Array<{ id: string, name: string, phone_number?: string | null, email?: string | null, status: string, address?: string | null, updated_at: string, province_id?: string | null, domain_id?: string | null, students_limit?: number | null, verified: boolean, classes: Array<{ class_students_aggregate: { aggregate?: { count: number } | null }, class_students: Array<{ learner_profile?: { user_attempts_aggregate: { aggregate?: { count: number } | null } } | null }> }>, domain?: { domain_name?: string | null, id: string, full_domain_name?: string | null } | null, province?: { name: string, id: string } | null, school_teacher_profiles: Array<{ teacher_profile: { user: { email?: string | null, id: string, phone_number?: string | null } } }> }> };

export type GetSchoolPkVariables = Types.Exact<{
  id: Types.Scalars['bigint']['input'];
}>;


export type GetSchoolPk = { schools_by_pk?: { id: string, name: string, phone_number?: string | null, email?: string | null, status: string, address?: string | null, updated_at: string, province_id?: string | null, domain_id?: string | null, students_limit?: number | null, verified: boolean, partner_roles: Array<{ id: string, value: string, partner_role_teacher_profiles: Array<{ role_id: string, teacher_profile_id: string, teacher_profile: { id: string, display_name: string, avatar_id?: string | null, user: { id: string, user_name?: string | null, phone_number?: string | null, email?: string | null } } }> }>, school_teacher_profiles: Array<{ teacher_profile_id: string, teacher_profile: { id: string, display_name: string, user: { email?: string | null, id: string, phone_number?: string | null } } }>, classes: Array<{ class_students_aggregate: { aggregate?: { count: number } | null }, class_students: Array<{ learner_profile?: { user_attempts_aggregate: { aggregate?: { count: number } | null } } | null }> }>, domain?: { domain_name?: string | null, id: string, full_domain_name?: string | null } | null, province?: { name: string, id: string } | null } | null };

export type GetSchoolsCountVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.SchoolsBoolExp>;
}>;


export type GetSchoolsCount = { schools_aggregate: { aggregate?: { count: number } | null } };

export type GetProvincesVariables = Types.Exact<{ [key: string]: never; }>;


export type GetProvinces = { provinces: Array<{ id: string, name: string }> };

export type GetNotificationsVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.NotificationsBoolExp>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  order_by?: Types.InputMaybe<Array<Types.NotificationsOrderBy> | Types.NotificationsOrderBy>;
}>;


export type GetNotifications = { notifications: Array<{ id: string, seen: boolean, description?: string | null, description_en?: string | null, image?: string | null, source: string, title?: string | null, title_en?: string | null, type: string, meta: Record<string, unknown> | Array<unknown>, created_at: string }> };

export type NotificationsAggregateVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.NotificationsBoolExp>;
}>;


export type NotificationsAggregate = { notifications_aggregate: { aggregate?: { count: number } | null } };

export type GetLearnerProfilesVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.LearnerProfilesBoolExp>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetLearnerProfiles = { learner_profiles: Array<{ id: string, display_name: string, avatar_id?: string | null, user?: { id: string, phone_number?: string | null, email?: string | null, subscription_expire_at?: string | null, user_name?: string | null } | null }> };

export type GetTeacherProfilesVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.TeacherProfilesBoolExp>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetTeacherProfiles = { teacher_profiles: Array<{ id: string, display_name: string, avatar_id?: string | null, user: { id: string, phone_number?: string | null, email?: string | null, subscription_expire_at?: string | null, user_name?: string | null } }> };

export type GetStudyTimeDayVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.AdminStudyTimeDayBoolExp>;
}>;


export type GetStudyTimeDay = { admin_study_time_day: Array<{ date?: string | null, total_execution_time?: number | null, total_exam_execution_time?: number | null, total_mini_execution_time?: number | null, total_speaking_execution_time?: number | null, total_ket_pet_speaking_execution_time?: number | null, total_ket_pet_writing_execution_time?: number | null, total_speaking_ielts_execution_time?: number | null, total_writing_execution_time?: number | null, total_time_mobile?: number | null, total_exam_execution_time_child?: number | null }> };

export type GetStudyTimeWeekVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.AdminStudyTimeWeekBoolExp>;
}>;


export type GetStudyTimeWeek = { admin_study_time_week: Array<{ date?: string | null, total_execution_time?: number | null, total_exam_execution_time?: number | null, total_mini_execution_time?: number | null, total_speaking_execution_time?: number | null, total_ket_pet_speaking_execution_time?: number | null, total_ket_pet_writing_execution_time?: number | null, total_speaking_ielts_execution_time?: number | null, total_writing_execution_time?: number | null, total_time_mobile?: number | null, total_exam_execution_time_child?: number | null }> };

export type GetStudyTimeMonthVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.AdminStudyTimeMonthBoolExp>;
}>;


export type GetStudyTimeMonth = { admin_study_time_month: Array<{ date?: string | null, total_execution_time?: number | null, total_exam_execution_time?: number | null, total_mini_execution_time?: number | null, total_speaking_execution_time?: number | null, total_ket_pet_speaking_execution_time?: number | null, total_ket_pet_writing_execution_time?: number | null, total_speaking_ielts_execution_time?: number | null, total_writing_execution_time?: number | null, total_time_mobile?: number | null, total_exam_execution_time_child?: number | null }> };

export type GetCompletedTestsRateDayVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.CompletedTestsRateByDayBoolExp>;
}>;


export type GetCompletedTestsRateDay = { completed_tests_rate_by_day: Array<{ date?: string | null, rate_premium?: number | null, rate_free?: number | null, total_completed_tests_premium?: string | null, total_completed_tests_free?: string | null, total_learner_profiles_premium?: string | null, total_learner_profiles_free?: string | null, total_completed_tests?: string | null, total_learner_profiles?: string | null, total_completed_tests_premium_exam?: string | null, total_completed_tests_free_exam?: string | null, total_completed_tests_premium_mini?: string | null, total_completed_tests_free_mini?: string | null, total_completed_tests_premium_practice?: string | null, total_completed_tests_free_practice?: string | null, total_completed_tests_free_placement_test?: string | null, total_mobile_completed_tests?: string | null, total_practice_mode_pre?: string | null, total_practice_mode_free?: string | null, total_completed_tests_ket_pet_speaking_pre?: string | null, total_completed_tests_ket_pet_speaking_free?: string | null, total_completed_tests_ket_pet_writing_pre?: string | null, total_completed_tests_ket_pet_writing_free?: string | null }> };

export type GetCompletedTestsRateWeekVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.CompletedTestsRateByWeekBoolExp>;
}>;


export type GetCompletedTestsRateWeek = { completed_tests_rate_by_week: Array<{ date?: string | null, rate_premium?: number | null, rate_free?: number | null, total_completed_tests_premium?: string | null, total_completed_tests_free?: string | null, total_learner_profiles_premium?: string | null, total_learner_profiles_free?: string | null, total_completed_tests?: string | null, total_learner_profiles?: string | null, total_completed_tests_premium_exam?: string | null, total_completed_tests_free_exam?: string | null, total_completed_tests_premium_mini?: string | null, total_completed_tests_free_mini?: string | null, total_completed_tests_premium_practice?: string | null, total_completed_tests_free_practice?: string | null, total_completed_tests_free_placement_test?: string | null, total_mobile_completed_tests?: string | null, total_practice_mode_pre?: string | null, total_practice_mode_free?: string | null, total_completed_tests_ket_pet_speaking_pre?: string | null, total_completed_tests_ket_pet_speaking_free?: string | null, total_completed_tests_ket_pet_writing_pre?: string | null, total_completed_tests_ket_pet_writing_free?: string | null }> };

export type GetCompletedTestsRateMonthVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.CompletedTestsRateByMonthBoolExp>;
}>;


export type GetCompletedTestsRateMonth = { completed_tests_rate_by_month: Array<{ date?: string | null, rate_premium?: number | null, rate_free?: number | null, total_completed_tests_premium?: string | null, total_completed_tests_free?: string | null, total_learner_profiles_premium?: string | null, total_learner_profiles_free?: string | null, total_completed_tests?: string | null, total_learner_profiles?: string | null, total_completed_tests_premium_exam?: string | null, total_completed_tests_free_exam?: string | null, total_completed_tests_premium_mini?: string | null, total_completed_tests_free_mini?: string | null, total_completed_tests_premium_practice?: string | null, total_completed_tests_free_practice?: string | null, total_completed_tests_free_placement_test?: string | null, total_mobile_completed_tests?: string | null, total_practice_mode_pre?: string | null, total_practice_mode_free?: string | null, total_completed_tests_ket_pet_speaking_pre?: string | null, total_completed_tests_ket_pet_speaking_free?: string | null, total_completed_tests_ket_pet_writing_pre?: string | null, total_completed_tests_ket_pet_writing_free?: string | null }> };

export type GetMembershipVariables = Types.Exact<{
  date?: Types.InputMaybe<Types.TimestampComparisonExp>;
}>;


export type GetMembership = { trial: Array<{ count?: number | null, date?: string | null, source_counts?: Record<string, unknown> | Array<unknown> | null }>, membership: Array<{ date?: string | null, codeTypeCount?: Record<string, unknown> | Array<unknown> | null }> };

export type GetUsersVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.UsersBoolExp>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  order_by?: Types.InputMaybe<Array<Types.UsersOrderBy> | Types.UsersOrderBy>;
}>;


export type GetUsers = { users: Array<{ id: string, email?: string | null, phone_number?: string | null, email_verified: boolean, subscription_expire_at?: string | null, meta: Record<string, unknown> | Array<unknown>, created_at: string, source_domain: string, utm_source?: string | null, utm_campaign?: string | null, utm_content?: string | null, utm_medium?: string | null, teacher_verified: boolean, diamond: number, user_name?: string | null, activation_codes: Array<{ activation_code: { code: string, discount?: { id: string, code: string } | null, flyer_package: { name: string, id: string } } }>, teacher_profile?: { about?: string | null, avatar_id?: string | null, created_at: string, display_name: string, gender?: string | null, id: string } | null, learner_profiles: Array<{ id: string, display_name: string, date_of_birth?: string | null, gender?: string | null, photo_url?: string | null, user_attempts_aggregate: { aggregate?: { count: number } | null }, school?: { id: string, name: string } | null, classes: Array<{ class_id: string, class?: { code: string, school?: { id: string, name: string } | null } | null }> }> }>, users_aggregate: { aggregate?: { count: number } | null } };

export type GetLearnerProfileCountVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.LearnerProfilesBoolExp>;
}>;


export type GetLearnerProfileCount = { learner_profiles_aggregate: { aggregate?: { count: number } | null } };

export type GetTestUndoneDayVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.TestUndoneDayBoolExp>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetTestUndoneDay = { test_undone_day: Array<{ meta?: Record<string, unknown> | Array<unknown> | null, total?: number | null, date?: string | null }> };

export type GetUserFreeToMembershipVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUserFreeToMembership = { user_free_to_membership: Array<{ avg?: number | null }> };

export type GetFeatureFlagVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.FeatureFlagBoolExp>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  order_by?: Types.InputMaybe<Array<Types.FeatureFlagOrderBy> | Types.FeatureFlagOrderBy>;
}>;


export type GetFeatureFlag = { feature_flag: Array<{ id: string, feature: string, user_ids?: Record<string, unknown> | Array<unknown> | null, domains?: Record<string, unknown> | Array<unknown> | null, class_ids?: Record<string, unknown> | Array<unknown> | null, teacher_ids?: Record<string, unknown> | Array<unknown> | null }> };

export type GetFeatureFlagAggregateVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.FeatureFlagBoolExp>;
}>;


export type GetFeatureFlagAggregate = { feature_flag_aggregate: { aggregate?: { count: number } | null } };

export type GetClassesVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.ClassesBoolExp>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetClasses = { classes: Array<{ id: string, code: string, name: string }> };

export type GetCustomDomainVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.CustomDomainBoolExp>;
}>;


export type GetCustomDomain = { custom_domain: Array<{ id: string, domain_name?: string | null }> };

export type ActivationPlansVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.PlansBoolExp>;
}>;


export type ActivationPlans = { plans: Array<{ id: string, day: string, name: string }> };

export type GetCheckpointSpeakingPartsCountVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.CheckPointSpeakingPartsBoolExp>;
}>;


export type GetCheckpointSpeakingPartsCount = { check_point_speaking_parts_aggregate: { aggregate?: { count: number } | null } };

export type GetCheckPointSpeakingPartVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.CheckPointSpeakingPartsBoolExp>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetCheckPointSpeakingPart = { check_point_speaking_parts: Array<{ check_point_id: string, order: number, position: number, speaking_part_id: string, speakingPart?: { id: string, name: string, title: string, partType: Types.SpeakingPartTypesEnum, position: number, createdAt: string, updatedAt: string, time?: string | null, userCreated?: { email?: string | null } | null, userUpdated?: { email?: string | null } | null } | null }> };

export type GetListPartsVariables = Types.Exact<{ [key: string]: never; }>;


export type GetListParts = { listParts: Array<{ order: number }> };

export type GetListPermissionsVariables = Types.Exact<{ [key: string]: never; }>;


export type GetListPermissions = { partner_permissions: Array<{ id: string, value: string }> };

export type GetActivationCodeUsersVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.ActivationCodeUsersBoolExp>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetActivationCodeUsers = { activation_code_users: Array<{ code: string, user_id?: string | null, activated_at: string, id: string, user?: { id: string, email?: string | null, phone_number?: string | null, diamond: number, user_name?: string | null } | null }> };

export type GetActivationCodeUsersCountVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.ActivationCodeUsersBoolExp>;
}>;


export type GetActivationCodeUsersCount = { activation_code_users_aggregate: { aggregate?: { count: number } | null } };

export type GetActivationCodesVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.ActivationCodesBoolExp>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  order_by?: Types.InputMaybe<Array<Types.ActivationCodesOrderBy> | Types.ActivationCodesOrderBy>;
}>;


export type GetActivationCodes = { activation_codes: Array<{ discount_id?: string | null, is_deleted: boolean, diamond?: number | null, max_uses?: number | null, uses: number, plan_day: string, code: string, type: Types.ActivationCodeTypeEnum, activated_at?: string | null, created_at: string, expired_at?: string | null, updated_at: string, description?: string | null, flyer_package: { id: string, name: string }, activation_users: Array<{ code: string, user_id?: string | null, activated_at: string, id: string, activation_code: { flyer_package: { name: string, id: string } }, user?: { id: string, email?: string | null, phone_number?: string | null, user_name?: string | null, subscription_expire_at?: string | null, diamond: number } | null }> }> };

export type GetActivationCodesCountVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.ActivationCodesBoolExp>;
}>;


export type GetActivationCodesCount = { activation_codes_aggregate: { aggregate?: { count: number } | null } };

export type GetPackagesVariables = Types.Exact<{
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  order_by?: Types.InputMaybe<Array<Types.PlansOrderBy> | Types.PlansOrderBy>;
}>;


export type GetPackages = { plans: Array<{ day: string, name: string, price: number, is_showing: boolean, description: string, created_at: string, updated_at: string, id: string, position: number }> };

export type GetPackagesCountVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPackagesCount = { plans_aggregate: { aggregate?: { count: number } | null } };

export type GetActivationCodeTypeVariables = Types.Exact<{ [key: string]: never; }>;


export type GetActivationCodeType = { activation_code_type: Array<{ value: string }> };

export type GetTestLevelsCountVariables = Types.Exact<{ [key: string]: never; }>;


export type GetTestLevelsCount = { test_levels_aggregate: { aggregate?: { count: number } | null } };

export type GetCheckPointsVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.CheckPointsBoolExp>;
  order_by?: Types.InputMaybe<Array<Types.CheckPointsOrderBy> | Types.CheckPointsOrderBy>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetCheckPoints = { check_points: Array<{ created_by?: string | null, teacher_id_bigint?: string | null, test_count: string, updated_by?: string | null, allow_review_answers: boolean, is_mixed: boolean, is_practice_exam: boolean, is_premium: boolean, remove?: boolean | null, public_status?: string | null, source?: string | null, teacher_id?: string | null, type: string, attempts_allowed: number, diamond?: number | null, order: number, time?: string | null, class_assigns?: Record<string, unknown> | Array<unknown> | null, domains?: Record<string, unknown> | Array<unknown> | null, level: Record<string, unknown> | Array<unknown>, meta?: Record<string, unknown> | Array<unknown> | null, skills?: Record<string, unknown> | Array<unknown> | null, max_grade: number, max_score?: number | null, description?: string | null, description_en?: string | null, image_url?: string | null, name: string, name_en?: string | null, created_at: string, updated_at: string, id: string, test_level_id?: string | null, test_level?: { name: string } | null, userCreated?: { id: string, email?: string | null, phone_number?: string | null, learner_profiles: Array<{ display_name: string, id: string }> } | null, userUpdated?: { id: string, email?: string | null, phone_number?: string | null, learner_profiles: Array<{ display_name: string, id: string }> } | null }> };

export type GetCheckPointsCountVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.CheckPointsBoolExp>;
}>;


export type GetCheckPointsCount = { check_points_aggregate: { aggregate?: { count: number } | null } };

export type GetPartExamVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.QuestionsBoolExp>;
  order_by?: Types.InputMaybe<Array<Types.QuestionsOrderBy> | Types.QuestionsOrderBy>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetPartExam = { questions: Array<{ id: string, name: string, question_type: Types.QuestionTypesEnum, exam_type: Types.ExamTypesEnum, time?: string | null, created_at: string, updated_at: string, title: string, userCreated?: { id: string, email?: string | null, phone_number?: string | null, learner_profiles: Array<{ display_name: string, id: string }> } | null, userUpdated?: { id: string, email?: string | null, phone_number?: string | null, learner_profiles: Array<{ display_name: string, id: string }> } | null }> };

export type GetPartExamCountVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.QuestionsBoolExp>;
}>;


export type GetPartExamCount = { questions_aggregate: { aggregate?: { count: number } | null } };

export type GetAdvicesVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.AdvicesBoolExp>;
  order_by?: Types.InputMaybe<Array<Types.AdvicesOrderBy> | Types.AdvicesOrderBy>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetAdvices = { advices: Array<{ id: string, learner_profile_id: string, advice_type: string, display_name: string, note_admin?: string | null, phone_number: string, status: string, created_at: string, updated_at: string, learner_profile: { id: string, display_name: string } }>, advices_aggregate: { aggregate?: { count: number } | null } };

export type GetUnCompletedTestsRateByDayVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.UnCompletedTestsRateByDayBoolExp>;
}>;


export type GetUnCompletedTestsRateByDay = { un_completed_tests_rate_by_day: Array<{ date?: string | null, total_un_completed_tests?: string | null, total_mobile_un_completed_tests?: string | null, total_practice_mode?: string | null, total_un_completed_tests_exam?: string | null, total_un_completed_tests_mini?: string | null }> };

export type GetUnCompletedTestsRateByWeekVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.UnCompletedTestsRateByWeekBoolExp>;
}>;


export type GetUnCompletedTestsRateByWeek = { un_completed_tests_rate_by_week: Array<{ date?: string | null, total_un_completed_tests?: string | null, total_mobile_un_completed_tests?: string | null, total_practice_mode?: string | null, total_un_completed_tests_exam?: string | null, total_un_completed_tests_mini?: string | null }> };

export type GetUnCompletedTestsRateByMonthVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.UnCompletedTestsRateByMonthBoolExp>;
}>;


export type GetUnCompletedTestsRateByMonth = { un_completed_tests_rate_by_month: Array<{ date?: string | null, total_un_completed_tests?: string | null, total_mobile_un_completed_tests?: string | null, total_practice_mode?: string | null, total_un_completed_tests_exam?: string | null, total_un_completed_tests_mini?: string | null }> };

export type GetSubscriptionsVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.SubscriptionsEntBoolExp>;
}>;


export type GetSubscriptions = { subscriptions_ent: Array<{ id: string, user_id: string, package_id: string, expired_at: string }> };


export const GetTeacherProfileDocument = gql`
    query GetTeacherProfile($where: teacher_profiles_bool_exp, $limit: Int) {
  teacher_profiles(where: $where, limit: $limit) {
    id
    display_name
    first_name
    user {
      id
      email
      phone_number
      user_name
    }
  }
}
    `;

/**
 * __useGetTeacherProfile__
 *
 * To run a query within a React component, call `useGetTeacherProfile` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherProfile` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherProfile({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetTeacherProfile(baseOptions?: Apollo.QueryHookOptions<GetTeacherProfile, GetTeacherProfileVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeacherProfile, GetTeacherProfileVariables>(GetTeacherProfileDocument, options);
      }
export function useGetTeacherProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeacherProfile, GetTeacherProfileVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeacherProfile, GetTeacherProfileVariables>(GetTeacherProfileDocument, options);
        }
export function useGetTeacherProfileSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTeacherProfile, GetTeacherProfileVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTeacherProfile, GetTeacherProfileVariables>(GetTeacherProfileDocument, options);
        }
export type GetTeacherProfileHookResult = ReturnType<typeof useGetTeacherProfile>;
export type GetTeacherProfileLazyQueryHookResult = ReturnType<typeof useGetTeacherProfileLazyQuery>;
export type GetTeacherProfileSuspenseQueryHookResult = ReturnType<typeof useGetTeacherProfileSuspenseQuery>;
export type GetTeacherProfileQueryResult = Apollo.QueryResult<GetTeacherProfile, GetTeacherProfileVariables>;
export const ListOrdersDocument = gql`
    query ListOrders($where: vnpay_payments_bool_exp, $order_by: [vnpay_payments_order_by!], $limit: Int, $offset: Int) {
  vnpay_payments(
    where: $where
    order_by: $order_by
    limit: $limit
    offset: $offset
  ) {
    active_code_users {
      activated_at
      code
      id
      new_user_id
      user_id
    }
    created_at
    email
    error_message
    flyer_active_code
    txn_ref
    user_name
    vnp_amount
    package_active
    id
    vnp_create_date
    status
    phone_number
  }
}
    `;

/**
 * __useListOrders__
 *
 * To run a query within a React component, call `useListOrders` and pass it any options that fit your needs.
 * When your component renders, `useListOrders` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrders({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListOrders(baseOptions?: Apollo.QueryHookOptions<ListOrders, ListOrdersVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListOrders, ListOrdersVariables>(ListOrdersDocument, options);
      }
export function useListOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOrders, ListOrdersVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListOrders, ListOrdersVariables>(ListOrdersDocument, options);
        }
export function useListOrdersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListOrders, ListOrdersVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListOrders, ListOrdersVariables>(ListOrdersDocument, options);
        }
export type ListOrdersHookResult = ReturnType<typeof useListOrders>;
export type ListOrdersLazyQueryHookResult = ReturnType<typeof useListOrdersLazyQuery>;
export type ListOrdersSuspenseQueryHookResult = ReturnType<typeof useListOrdersSuspenseQuery>;
export type ListOrdersQueryResult = Apollo.QueryResult<ListOrders, ListOrdersVariables>;
export const CountOrdersDocument = gql`
    query CountOrders($where: vnpay_payments_bool_exp) {
  vnpay_payments_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useCountOrders__
 *
 * To run a query within a React component, call `useCountOrders` and pass it any options that fit your needs.
 * When your component renders, `useCountOrders` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountOrders({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCountOrders(baseOptions?: Apollo.QueryHookOptions<CountOrders, CountOrdersVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountOrders, CountOrdersVariables>(CountOrdersDocument, options);
      }
export function useCountOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountOrders, CountOrdersVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountOrders, CountOrdersVariables>(CountOrdersDocument, options);
        }
export function useCountOrdersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CountOrders, CountOrdersVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CountOrders, CountOrdersVariables>(CountOrdersDocument, options);
        }
export type CountOrdersHookResult = ReturnType<typeof useCountOrders>;
export type CountOrdersLazyQueryHookResult = ReturnType<typeof useCountOrdersLazyQuery>;
export type CountOrdersSuspenseQueryHookResult = ReturnType<typeof useCountOrdersSuspenseQuery>;
export type CountOrdersQueryResult = Apollo.QueryResult<CountOrders, CountOrdersVariables>;
export const ListCustomDomainsDocument = gql`
    query ListCustomDomains($where: custom_domain_bool_exp, $limit: Int, $offset: Int, $order_by: [custom_domain_order_by!]) {
  custom_domain(
    where: $where
    order_by: $order_by
    limit: $limit
    offset: $offset
  ) {
    created_at
    domain_name
    id
    status
    user_email
    rejected_domain_name
    user_phone_number
    users {
      id
      subscription_expire_at
    }
    show_diamond
    schools {
      classes {
        class_students {
          learner_profile {
            user_attempts_aggregate(where: {submitted_at: {_is_null: false}}) {
              aggregate {
                count
              }
            }
          }
        }
      }
    }
    teacher_profile {
      display_name
      first_name
      id
      last_name
      user {
        email
        id
        phone_number
        user_name
      }
    }
  }
}
    `;

/**
 * __useListCustomDomains__
 *
 * To run a query within a React component, call `useListCustomDomains` and pass it any options that fit your needs.
 * When your component renders, `useListCustomDomains` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCustomDomains({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useListCustomDomains(baseOptions?: Apollo.QueryHookOptions<ListCustomDomains, ListCustomDomainsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCustomDomains, ListCustomDomainsVariables>(ListCustomDomainsDocument, options);
      }
export function useListCustomDomainsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCustomDomains, ListCustomDomainsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCustomDomains, ListCustomDomainsVariables>(ListCustomDomainsDocument, options);
        }
export function useListCustomDomainsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListCustomDomains, ListCustomDomainsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListCustomDomains, ListCustomDomainsVariables>(ListCustomDomainsDocument, options);
        }
export type ListCustomDomainsHookResult = ReturnType<typeof useListCustomDomains>;
export type ListCustomDomainsLazyQueryHookResult = ReturnType<typeof useListCustomDomainsLazyQuery>;
export type ListCustomDomainsSuspenseQueryHookResult = ReturnType<typeof useListCustomDomainsSuspenseQuery>;
export type ListCustomDomainsQueryResult = Apollo.QueryResult<ListCustomDomains, ListCustomDomainsVariables>;
export const CountCustomDomainsDocument = gql`
    query CountCustomDomains($where: custom_domain_bool_exp) {
  custom_domain_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useCountCustomDomains__
 *
 * To run a query within a React component, call `useCountCustomDomains` and pass it any options that fit your needs.
 * When your component renders, `useCountCustomDomains` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountCustomDomains({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCountCustomDomains(baseOptions?: Apollo.QueryHookOptions<CountCustomDomains, CountCustomDomainsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountCustomDomains, CountCustomDomainsVariables>(CountCustomDomainsDocument, options);
      }
export function useCountCustomDomainsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountCustomDomains, CountCustomDomainsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountCustomDomains, CountCustomDomainsVariables>(CountCustomDomainsDocument, options);
        }
export function useCountCustomDomainsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CountCustomDomains, CountCustomDomainsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CountCustomDomains, CountCustomDomainsVariables>(CountCustomDomainsDocument, options);
        }
export type CountCustomDomainsHookResult = ReturnType<typeof useCountCustomDomains>;
export type CountCustomDomainsLazyQueryHookResult = ReturnType<typeof useCountCustomDomainsLazyQuery>;
export type CountCustomDomainsSuspenseQueryHookResult = ReturnType<typeof useCountCustomDomainsSuspenseQuery>;
export type CountCustomDomainsQueryResult = Apollo.QueryResult<CountCustomDomains, CountCustomDomainsVariables>;
export const GetTestLevelsDocument = gql`
    query getTestLevels {
  testLevels: test_levels(order_by: {order: asc}) {
    id
    image_url
    levels
    slug
    name
    order
    color
    updated_at
    created_at
    description
    type
    description_en
    exam_settings {
      execution_time
      exam_type
    }
  }
}
    `;

/**
 * __useGetTestLevels__
 *
 * To run a query within a React component, call `useGetTestLevels` and pass it any options that fit your needs.
 * When your component renders, `useGetTestLevels` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTestLevels({
 *   variables: {
 *   },
 * });
 */
export function useGetTestLevels(baseOptions?: Apollo.QueryHookOptions<GetTestLevels, GetTestLevelsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTestLevels, GetTestLevelsVariables>(GetTestLevelsDocument, options);
      }
export function useGetTestLevelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTestLevels, GetTestLevelsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTestLevels, GetTestLevelsVariables>(GetTestLevelsDocument, options);
        }
export function useGetTestLevelsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTestLevels, GetTestLevelsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTestLevels, GetTestLevelsVariables>(GetTestLevelsDocument, options);
        }
export type GetTestLevelsHookResult = ReturnType<typeof useGetTestLevels>;
export type GetTestLevelsLazyQueryHookResult = ReturnType<typeof useGetTestLevelsLazyQuery>;
export type GetTestLevelsSuspenseQueryHookResult = ReturnType<typeof useGetTestLevelsSuspenseQuery>;
export type GetTestLevelsQueryResult = Apollo.QueryResult<GetTestLevels, GetTestLevelsVariables>;
export const GetSpeakingPartsCountDocument = gql`
    query getSpeakingPartsCount($where: speaking_parts_bool_exp) {
  speakingPartsAggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetSpeakingPartsCount__
 *
 * To run a query within a React component, call `useGetSpeakingPartsCount` and pass it any options that fit your needs.
 * When your component renders, `useGetSpeakingPartsCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpeakingPartsCount({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetSpeakingPartsCount(baseOptions?: Apollo.QueryHookOptions<GetSpeakingPartsCount, GetSpeakingPartsCountVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpeakingPartsCount, GetSpeakingPartsCountVariables>(GetSpeakingPartsCountDocument, options);
      }
export function useGetSpeakingPartsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpeakingPartsCount, GetSpeakingPartsCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpeakingPartsCount, GetSpeakingPartsCountVariables>(GetSpeakingPartsCountDocument, options);
        }
export function useGetSpeakingPartsCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSpeakingPartsCount, GetSpeakingPartsCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSpeakingPartsCount, GetSpeakingPartsCountVariables>(GetSpeakingPartsCountDocument, options);
        }
export type GetSpeakingPartsCountHookResult = ReturnType<typeof useGetSpeakingPartsCount>;
export type GetSpeakingPartsCountLazyQueryHookResult = ReturnType<typeof useGetSpeakingPartsCountLazyQuery>;
export type GetSpeakingPartsCountSuspenseQueryHookResult = ReturnType<typeof useGetSpeakingPartsCountSuspenseQuery>;
export type GetSpeakingPartsCountQueryResult = Apollo.QueryResult<GetSpeakingPartsCount, GetSpeakingPartsCountVariables>;
export const GetSpeakingPartsDocument = gql`
    query getSpeakingParts($where: speaking_parts_bool_exp, $limit: Int, $offset: Int) {
  speakingParts(
    where: $where
    limit: $limit
    offset: $offset
    order_by: {id: desc}
  ) {
    ...ListSpeakingPartSelection
  }
}
    ${ListSpeakingPartSelection}`;

/**
 * __useGetSpeakingParts__
 *
 * To run a query within a React component, call `useGetSpeakingParts` and pass it any options that fit your needs.
 * When your component renders, `useGetSpeakingParts` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpeakingParts({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetSpeakingParts(baseOptions?: Apollo.QueryHookOptions<GetSpeakingParts, GetSpeakingPartsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpeakingParts, GetSpeakingPartsVariables>(GetSpeakingPartsDocument, options);
      }
export function useGetSpeakingPartsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpeakingParts, GetSpeakingPartsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpeakingParts, GetSpeakingPartsVariables>(GetSpeakingPartsDocument, options);
        }
export function useGetSpeakingPartsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSpeakingParts, GetSpeakingPartsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSpeakingParts, GetSpeakingPartsVariables>(GetSpeakingPartsDocument, options);
        }
export type GetSpeakingPartsHookResult = ReturnType<typeof useGetSpeakingParts>;
export type GetSpeakingPartsLazyQueryHookResult = ReturnType<typeof useGetSpeakingPartsLazyQuery>;
export type GetSpeakingPartsSuspenseQueryHookResult = ReturnType<typeof useGetSpeakingPartsSuspenseQuery>;
export type GetSpeakingPartsQueryResult = Apollo.QueryResult<GetSpeakingParts, GetSpeakingPartsVariables>;
export const GetSpeakingPartDocument = gql`
    query getSpeakingPart($id: bigint!) {
  speakingPart(id: $id) {
    ...DetailSpeakingPartSelection
  }
}
    ${DetailSpeakingPartSelection}`;

/**
 * __useGetSpeakingPart__
 *
 * To run a query within a React component, call `useGetSpeakingPart` and pass it any options that fit your needs.
 * When your component renders, `useGetSpeakingPart` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpeakingPart({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSpeakingPart(baseOptions: Apollo.QueryHookOptions<GetSpeakingPart, GetSpeakingPartVariables> & ({ variables: GetSpeakingPartVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpeakingPart, GetSpeakingPartVariables>(GetSpeakingPartDocument, options);
      }
export function useGetSpeakingPartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpeakingPart, GetSpeakingPartVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpeakingPart, GetSpeakingPartVariables>(GetSpeakingPartDocument, options);
        }
export function useGetSpeakingPartSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSpeakingPart, GetSpeakingPartVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSpeakingPart, GetSpeakingPartVariables>(GetSpeakingPartDocument, options);
        }
export type GetSpeakingPartHookResult = ReturnType<typeof useGetSpeakingPart>;
export type GetSpeakingPartLazyQueryHookResult = ReturnType<typeof useGetSpeakingPartLazyQuery>;
export type GetSpeakingPartSuspenseQueryHookResult = ReturnType<typeof useGetSpeakingPartSuspenseQuery>;
export type GetSpeakingPartQueryResult = Apollo.QueryResult<GetSpeakingPart, GetSpeakingPartVariables>;
export const GetSpeakingCheckPointsCountDocument = gql`
    query getSpeakingCheckPointsCount($where: speaking_check_points_bool_exp) {
  speakingCheckPointsAggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetSpeakingCheckPointsCount__
 *
 * To run a query within a React component, call `useGetSpeakingCheckPointsCount` and pass it any options that fit your needs.
 * When your component renders, `useGetSpeakingCheckPointsCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpeakingCheckPointsCount({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetSpeakingCheckPointsCount(baseOptions?: Apollo.QueryHookOptions<GetSpeakingCheckPointsCount, GetSpeakingCheckPointsCountVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpeakingCheckPointsCount, GetSpeakingCheckPointsCountVariables>(GetSpeakingCheckPointsCountDocument, options);
      }
export function useGetSpeakingCheckPointsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpeakingCheckPointsCount, GetSpeakingCheckPointsCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpeakingCheckPointsCount, GetSpeakingCheckPointsCountVariables>(GetSpeakingCheckPointsCountDocument, options);
        }
export function useGetSpeakingCheckPointsCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSpeakingCheckPointsCount, GetSpeakingCheckPointsCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSpeakingCheckPointsCount, GetSpeakingCheckPointsCountVariables>(GetSpeakingCheckPointsCountDocument, options);
        }
export type GetSpeakingCheckPointsCountHookResult = ReturnType<typeof useGetSpeakingCheckPointsCount>;
export type GetSpeakingCheckPointsCountLazyQueryHookResult = ReturnType<typeof useGetSpeakingCheckPointsCountLazyQuery>;
export type GetSpeakingCheckPointsCountSuspenseQueryHookResult = ReturnType<typeof useGetSpeakingCheckPointsCountSuspenseQuery>;
export type GetSpeakingCheckPointsCountQueryResult = Apollo.QueryResult<GetSpeakingCheckPointsCount, GetSpeakingCheckPointsCountVariables>;
export const GetSpeakingCheckPointsDocument = gql`
    query getSpeakingCheckPoints($where: speaking_check_points_bool_exp, $limit: Int, $offset: Int) {
  speakingCheckPoints(
    where: $where
    limit: $limit
    offset: $offset
    order_by: {checkPoint: {updated_at: desc}}
  ) {
    checkPointId
    checkPoint {
      ...ListCheckPointSelection
    }
    testLevel {
      id
      name
    }
  }
}
    ${ListCheckPointSelection}`;

/**
 * __useGetSpeakingCheckPoints__
 *
 * To run a query within a React component, call `useGetSpeakingCheckPoints` and pass it any options that fit your needs.
 * When your component renders, `useGetSpeakingCheckPoints` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpeakingCheckPoints({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetSpeakingCheckPoints(baseOptions?: Apollo.QueryHookOptions<GetSpeakingCheckPoints, GetSpeakingCheckPointsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpeakingCheckPoints, GetSpeakingCheckPointsVariables>(GetSpeakingCheckPointsDocument, options);
      }
export function useGetSpeakingCheckPointsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpeakingCheckPoints, GetSpeakingCheckPointsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpeakingCheckPoints, GetSpeakingCheckPointsVariables>(GetSpeakingCheckPointsDocument, options);
        }
export function useGetSpeakingCheckPointsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSpeakingCheckPoints, GetSpeakingCheckPointsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSpeakingCheckPoints, GetSpeakingCheckPointsVariables>(GetSpeakingCheckPointsDocument, options);
        }
export type GetSpeakingCheckPointsHookResult = ReturnType<typeof useGetSpeakingCheckPoints>;
export type GetSpeakingCheckPointsLazyQueryHookResult = ReturnType<typeof useGetSpeakingCheckPointsLazyQuery>;
export type GetSpeakingCheckPointsSuspenseQueryHookResult = ReturnType<typeof useGetSpeakingCheckPointsSuspenseQuery>;
export type GetSpeakingCheckPointsQueryResult = Apollo.QueryResult<GetSpeakingCheckPoints, GetSpeakingCheckPointsVariables>;
export const GetSpeakingCheckPointDocument = gql`
    query getSpeakingCheckPoint($checkPointId: uuid!) {
  speakingCheckPoint(checkPointId: $checkPointId) {
    ...DetailSpeakingCheckPointSelection
  }
}
    ${DetailSpeakingCheckPointSelection}`;

/**
 * __useGetSpeakingCheckPoint__
 *
 * To run a query within a React component, call `useGetSpeakingCheckPoint` and pass it any options that fit your needs.
 * When your component renders, `useGetSpeakingCheckPoint` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpeakingCheckPoint({
 *   variables: {
 *      checkPointId: // value for 'checkPointId'
 *   },
 * });
 */
export function useGetSpeakingCheckPoint(baseOptions: Apollo.QueryHookOptions<GetSpeakingCheckPoint, GetSpeakingCheckPointVariables> & ({ variables: GetSpeakingCheckPointVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpeakingCheckPoint, GetSpeakingCheckPointVariables>(GetSpeakingCheckPointDocument, options);
      }
export function useGetSpeakingCheckPointLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpeakingCheckPoint, GetSpeakingCheckPointVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpeakingCheckPoint, GetSpeakingCheckPointVariables>(GetSpeakingCheckPointDocument, options);
        }
export function useGetSpeakingCheckPointSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSpeakingCheckPoint, GetSpeakingCheckPointVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSpeakingCheckPoint, GetSpeakingCheckPointVariables>(GetSpeakingCheckPointDocument, options);
        }
export type GetSpeakingCheckPointHookResult = ReturnType<typeof useGetSpeakingCheckPoint>;
export type GetSpeakingCheckPointLazyQueryHookResult = ReturnType<typeof useGetSpeakingCheckPointLazyQuery>;
export type GetSpeakingCheckPointSuspenseQueryHookResult = ReturnType<typeof useGetSpeakingCheckPointSuspenseQuery>;
export type GetSpeakingCheckPointQueryResult = Apollo.QueryResult<GetSpeakingCheckPoint, GetSpeakingCheckPointVariables>;
export const GetWritingCheckPointsCountDocument = gql`
    query getWritingCheckPointsCount($where: writing_check_points_bool_exp) {
  writingCheckPointsAggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetWritingCheckPointsCount__
 *
 * To run a query within a React component, call `useGetWritingCheckPointsCount` and pass it any options that fit your needs.
 * When your component renders, `useGetWritingCheckPointsCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWritingCheckPointsCount({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetWritingCheckPointsCount(baseOptions?: Apollo.QueryHookOptions<GetWritingCheckPointsCount, GetWritingCheckPointsCountVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWritingCheckPointsCount, GetWritingCheckPointsCountVariables>(GetWritingCheckPointsCountDocument, options);
      }
export function useGetWritingCheckPointsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWritingCheckPointsCount, GetWritingCheckPointsCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWritingCheckPointsCount, GetWritingCheckPointsCountVariables>(GetWritingCheckPointsCountDocument, options);
        }
export function useGetWritingCheckPointsCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetWritingCheckPointsCount, GetWritingCheckPointsCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetWritingCheckPointsCount, GetWritingCheckPointsCountVariables>(GetWritingCheckPointsCountDocument, options);
        }
export type GetWritingCheckPointsCountHookResult = ReturnType<typeof useGetWritingCheckPointsCount>;
export type GetWritingCheckPointsCountLazyQueryHookResult = ReturnType<typeof useGetWritingCheckPointsCountLazyQuery>;
export type GetWritingCheckPointsCountSuspenseQueryHookResult = ReturnType<typeof useGetWritingCheckPointsCountSuspenseQuery>;
export type GetWritingCheckPointsCountQueryResult = Apollo.QueryResult<GetWritingCheckPointsCount, GetWritingCheckPointsCountVariables>;
export const GetWritingCheckPointsDocument = gql`
    query getWritingCheckPoints($where: writing_check_points_bool_exp, $limit: Int, $offset: Int) {
  writingCheckPoints(
    where: $where
    limit: $limit
    offset: $offset
    order_by: {checkPoint: {updated_at: desc}}
  ) {
    checkPointId
    checkPoint {
      ...ListCheckPointSelection
    }
    testLevel {
      id
      name
    }
  }
}
    ${ListCheckPointSelection}`;

/**
 * __useGetWritingCheckPoints__
 *
 * To run a query within a React component, call `useGetWritingCheckPoints` and pass it any options that fit your needs.
 * When your component renders, `useGetWritingCheckPoints` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWritingCheckPoints({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetWritingCheckPoints(baseOptions?: Apollo.QueryHookOptions<GetWritingCheckPoints, GetWritingCheckPointsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWritingCheckPoints, GetWritingCheckPointsVariables>(GetWritingCheckPointsDocument, options);
      }
export function useGetWritingCheckPointsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWritingCheckPoints, GetWritingCheckPointsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWritingCheckPoints, GetWritingCheckPointsVariables>(GetWritingCheckPointsDocument, options);
        }
export function useGetWritingCheckPointsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetWritingCheckPoints, GetWritingCheckPointsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetWritingCheckPoints, GetWritingCheckPointsVariables>(GetWritingCheckPointsDocument, options);
        }
export type GetWritingCheckPointsHookResult = ReturnType<typeof useGetWritingCheckPoints>;
export type GetWritingCheckPointsLazyQueryHookResult = ReturnType<typeof useGetWritingCheckPointsLazyQuery>;
export type GetWritingCheckPointsSuspenseQueryHookResult = ReturnType<typeof useGetWritingCheckPointsSuspenseQuery>;
export type GetWritingCheckPointsQueryResult = Apollo.QueryResult<GetWritingCheckPoints, GetWritingCheckPointsVariables>;
export const GetWritingCheckPointDocument = gql`
    query getWritingCheckPoint($checkPointId: uuid!) {
  writingCheckPoint(checkPointId: $checkPointId) {
    ...DetailWritingCheckPointSelection
  }
}
    ${DetailWritingCheckPointSelection}`;

/**
 * __useGetWritingCheckPoint__
 *
 * To run a query within a React component, call `useGetWritingCheckPoint` and pass it any options that fit your needs.
 * When your component renders, `useGetWritingCheckPoint` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWritingCheckPoint({
 *   variables: {
 *      checkPointId: // value for 'checkPointId'
 *   },
 * });
 */
export function useGetWritingCheckPoint(baseOptions: Apollo.QueryHookOptions<GetWritingCheckPoint, GetWritingCheckPointVariables> & ({ variables: GetWritingCheckPointVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWritingCheckPoint, GetWritingCheckPointVariables>(GetWritingCheckPointDocument, options);
      }
export function useGetWritingCheckPointLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWritingCheckPoint, GetWritingCheckPointVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWritingCheckPoint, GetWritingCheckPointVariables>(GetWritingCheckPointDocument, options);
        }
export function useGetWritingCheckPointSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetWritingCheckPoint, GetWritingCheckPointVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetWritingCheckPoint, GetWritingCheckPointVariables>(GetWritingCheckPointDocument, options);
        }
export type GetWritingCheckPointHookResult = ReturnType<typeof useGetWritingCheckPoint>;
export type GetWritingCheckPointLazyQueryHookResult = ReturnType<typeof useGetWritingCheckPointLazyQuery>;
export type GetWritingCheckPointSuspenseQueryHookResult = ReturnType<typeof useGetWritingCheckPointSuspenseQuery>;
export type GetWritingCheckPointQueryResult = Apollo.QueryResult<GetWritingCheckPoint, GetWritingCheckPointVariables>;
export const GetCheckPointDocument = gql`
    query getCheckPoint($checkPointId: uuid!) {
  check_points_by_pk(id: $checkPointId) {
    ...DetailCheckPointSelection
  }
}
    ${DetailCheckPointSelection}`;

/**
 * __useGetCheckPoint__
 *
 * To run a query within a React component, call `useGetCheckPoint` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckPoint` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckPoint({
 *   variables: {
 *      checkPointId: // value for 'checkPointId'
 *   },
 * });
 */
export function useGetCheckPoint(baseOptions: Apollo.QueryHookOptions<GetCheckPoint, GetCheckPointVariables> & ({ variables: GetCheckPointVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCheckPoint, GetCheckPointVariables>(GetCheckPointDocument, options);
      }
export function useGetCheckPointLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCheckPoint, GetCheckPointVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCheckPoint, GetCheckPointVariables>(GetCheckPointDocument, options);
        }
export function useGetCheckPointSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCheckPoint, GetCheckPointVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCheckPoint, GetCheckPointVariables>(GetCheckPointDocument, options);
        }
export type GetCheckPointHookResult = ReturnType<typeof useGetCheckPoint>;
export type GetCheckPointLazyQueryHookResult = ReturnType<typeof useGetCheckPointLazyQuery>;
export type GetCheckPointSuspenseQueryHookResult = ReturnType<typeof useGetCheckPointSuspenseQuery>;
export type GetCheckPointQueryResult = Apollo.QueryResult<GetCheckPoint, GetCheckPointVariables>;
export const GetWritingPartsCountDocument = gql`
    query getWritingPartsCount($where: writing_parts_bool_exp) {
  writingPartsAggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetWritingPartsCount__
 *
 * To run a query within a React component, call `useGetWritingPartsCount` and pass it any options that fit your needs.
 * When your component renders, `useGetWritingPartsCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWritingPartsCount({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetWritingPartsCount(baseOptions?: Apollo.QueryHookOptions<GetWritingPartsCount, GetWritingPartsCountVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWritingPartsCount, GetWritingPartsCountVariables>(GetWritingPartsCountDocument, options);
      }
export function useGetWritingPartsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWritingPartsCount, GetWritingPartsCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWritingPartsCount, GetWritingPartsCountVariables>(GetWritingPartsCountDocument, options);
        }
export function useGetWritingPartsCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetWritingPartsCount, GetWritingPartsCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetWritingPartsCount, GetWritingPartsCountVariables>(GetWritingPartsCountDocument, options);
        }
export type GetWritingPartsCountHookResult = ReturnType<typeof useGetWritingPartsCount>;
export type GetWritingPartsCountLazyQueryHookResult = ReturnType<typeof useGetWritingPartsCountLazyQuery>;
export type GetWritingPartsCountSuspenseQueryHookResult = ReturnType<typeof useGetWritingPartsCountSuspenseQuery>;
export type GetWritingPartsCountQueryResult = Apollo.QueryResult<GetWritingPartsCount, GetWritingPartsCountVariables>;
export const GetWritingPartsDocument = gql`
    query getWritingParts($where: writing_parts_bool_exp, $limit: Int, $offset: Int) {
  writingParts(
    where: $where
    limit: $limit
    offset: $offset
    order_by: {id: desc}
  ) {
    ...ListWritingPartSelection
  }
}
    ${ListWritingPartSelection}`;

/**
 * __useGetWritingParts__
 *
 * To run a query within a React component, call `useGetWritingParts` and pass it any options that fit your needs.
 * When your component renders, `useGetWritingParts` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWritingParts({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetWritingParts(baseOptions?: Apollo.QueryHookOptions<GetWritingParts, GetWritingPartsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWritingParts, GetWritingPartsVariables>(GetWritingPartsDocument, options);
      }
export function useGetWritingPartsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWritingParts, GetWritingPartsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWritingParts, GetWritingPartsVariables>(GetWritingPartsDocument, options);
        }
export function useGetWritingPartsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetWritingParts, GetWritingPartsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetWritingParts, GetWritingPartsVariables>(GetWritingPartsDocument, options);
        }
export type GetWritingPartsHookResult = ReturnType<typeof useGetWritingParts>;
export type GetWritingPartsLazyQueryHookResult = ReturnType<typeof useGetWritingPartsLazyQuery>;
export type GetWritingPartsSuspenseQueryHookResult = ReturnType<typeof useGetWritingPartsSuspenseQuery>;
export type GetWritingPartsQueryResult = Apollo.QueryResult<GetWritingParts, GetWritingPartsVariables>;
export const GetWritingPartDocument = gql`
    query getWritingPart($id: bigint!) {
  writingPart(id: $id) {
    ...DetailWritingPartSelection
  }
}
    ${DetailWritingPartSelection}`;

/**
 * __useGetWritingPart__
 *
 * To run a query within a React component, call `useGetWritingPart` and pass it any options that fit your needs.
 * When your component renders, `useGetWritingPart` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWritingPart({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWritingPart(baseOptions: Apollo.QueryHookOptions<GetWritingPart, GetWritingPartVariables> & ({ variables: GetWritingPartVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWritingPart, GetWritingPartVariables>(GetWritingPartDocument, options);
      }
export function useGetWritingPartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWritingPart, GetWritingPartVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWritingPart, GetWritingPartVariables>(GetWritingPartDocument, options);
        }
export function useGetWritingPartSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetWritingPart, GetWritingPartVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetWritingPart, GetWritingPartVariables>(GetWritingPartDocument, options);
        }
export type GetWritingPartHookResult = ReturnType<typeof useGetWritingPart>;
export type GetWritingPartLazyQueryHookResult = ReturnType<typeof useGetWritingPartLazyQuery>;
export type GetWritingPartSuspenseQueryHookResult = ReturnType<typeof useGetWritingPartSuspenseQuery>;
export type GetWritingPartQueryResult = Apollo.QueryResult<GetWritingPart, GetWritingPartVariables>;
export const GetSchoolsDocument = gql`
    query getSchools($where: schools_bool_exp, $limit: Int, $offset: Int) {
  schools(where: $where, limit: $limit, offset: $offset, order_by: {id: desc}) {
    ...DetailSchoolSelection
  }
}
    ${DetailSchoolSelection}`;

/**
 * __useGetSchools__
 *
 * To run a query within a React component, call `useGetSchools` and pass it any options that fit your needs.
 * When your component renders, `useGetSchools` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchools({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetSchools(baseOptions?: Apollo.QueryHookOptions<GetSchools, GetSchoolsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchools, GetSchoolsVariables>(GetSchoolsDocument, options);
      }
export function useGetSchoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchools, GetSchoolsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchools, GetSchoolsVariables>(GetSchoolsDocument, options);
        }
export function useGetSchoolsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSchools, GetSchoolsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSchools, GetSchoolsVariables>(GetSchoolsDocument, options);
        }
export type GetSchoolsHookResult = ReturnType<typeof useGetSchools>;
export type GetSchoolsLazyQueryHookResult = ReturnType<typeof useGetSchoolsLazyQuery>;
export type GetSchoolsSuspenseQueryHookResult = ReturnType<typeof useGetSchoolsSuspenseQuery>;
export type GetSchoolsQueryResult = Apollo.QueryResult<GetSchools, GetSchoolsVariables>;
export const GetSchoolPkDocument = gql`
    query getSchoolPk($id: bigint!) {
  schools_by_pk(id: $id) {
    ...DetailSchoolSelection
    partner_roles {
      id
      value
      partner_role_teacher_profiles {
        role_id
        teacher_profile_id
        teacher_profile {
          id
          display_name
          avatar_id
          user {
            id
            user_name
            phone_number
            email
          }
        }
      }
    }
    school_teacher_profiles {
      teacher_profile_id
      teacher_profile {
        id
        display_name
      }
    }
  }
}
    ${DetailSchoolSelection}`;

/**
 * __useGetSchoolPk__
 *
 * To run a query within a React component, call `useGetSchoolPk` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolPk` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolPk({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSchoolPk(baseOptions: Apollo.QueryHookOptions<GetSchoolPk, GetSchoolPkVariables> & ({ variables: GetSchoolPkVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchoolPk, GetSchoolPkVariables>(GetSchoolPkDocument, options);
      }
export function useGetSchoolPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchoolPk, GetSchoolPkVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchoolPk, GetSchoolPkVariables>(GetSchoolPkDocument, options);
        }
export function useGetSchoolPkSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSchoolPk, GetSchoolPkVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSchoolPk, GetSchoolPkVariables>(GetSchoolPkDocument, options);
        }
export type GetSchoolPkHookResult = ReturnType<typeof useGetSchoolPk>;
export type GetSchoolPkLazyQueryHookResult = ReturnType<typeof useGetSchoolPkLazyQuery>;
export type GetSchoolPkSuspenseQueryHookResult = ReturnType<typeof useGetSchoolPkSuspenseQuery>;
export type GetSchoolPkQueryResult = Apollo.QueryResult<GetSchoolPk, GetSchoolPkVariables>;
export const GetSchoolsCountDocument = gql`
    query getSchoolsCount($where: schools_bool_exp) {
  schools_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetSchoolsCount__
 *
 * To run a query within a React component, call `useGetSchoolsCount` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolsCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolsCount({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetSchoolsCount(baseOptions?: Apollo.QueryHookOptions<GetSchoolsCount, GetSchoolsCountVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchoolsCount, GetSchoolsCountVariables>(GetSchoolsCountDocument, options);
      }
export function useGetSchoolsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchoolsCount, GetSchoolsCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchoolsCount, GetSchoolsCountVariables>(GetSchoolsCountDocument, options);
        }
export function useGetSchoolsCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSchoolsCount, GetSchoolsCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSchoolsCount, GetSchoolsCountVariables>(GetSchoolsCountDocument, options);
        }
export type GetSchoolsCountHookResult = ReturnType<typeof useGetSchoolsCount>;
export type GetSchoolsCountLazyQueryHookResult = ReturnType<typeof useGetSchoolsCountLazyQuery>;
export type GetSchoolsCountSuspenseQueryHookResult = ReturnType<typeof useGetSchoolsCountSuspenseQuery>;
export type GetSchoolsCountQueryResult = Apollo.QueryResult<GetSchoolsCount, GetSchoolsCountVariables>;
export const GetProvincesDocument = gql`
    query getProvinces {
  provinces: provinces {
    id
    name
  }
}
    `;

/**
 * __useGetProvinces__
 *
 * To run a query within a React component, call `useGetProvinces` and pass it any options that fit your needs.
 * When your component renders, `useGetProvinces` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProvinces({
 *   variables: {
 *   },
 * });
 */
export function useGetProvinces(baseOptions?: Apollo.QueryHookOptions<GetProvinces, GetProvincesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProvinces, GetProvincesVariables>(GetProvincesDocument, options);
      }
export function useGetProvincesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProvinces, GetProvincesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProvinces, GetProvincesVariables>(GetProvincesDocument, options);
        }
export function useGetProvincesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetProvinces, GetProvincesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProvinces, GetProvincesVariables>(GetProvincesDocument, options);
        }
export type GetProvincesHookResult = ReturnType<typeof useGetProvinces>;
export type GetProvincesLazyQueryHookResult = ReturnType<typeof useGetProvincesLazyQuery>;
export type GetProvincesSuspenseQueryHookResult = ReturnType<typeof useGetProvincesSuspenseQuery>;
export type GetProvincesQueryResult = Apollo.QueryResult<GetProvinces, GetProvincesVariables>;
export const GetNotificationsDocument = gql`
    query getNotifications($where: notifications_bool_exp, $limit: Int, $offset: Int, $order_by: [notifications_order_by!]) {
  notifications(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    id
    seen
    description
    description_en
    image
    source
    title
    title_en
    type
    meta
    created_at
  }
}
    `;

/**
 * __useGetNotifications__
 *
 * To run a query within a React component, call `useGetNotifications` and pass it any options that fit your needs.
 * When your component renders, `useGetNotifications` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotifications({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetNotifications(baseOptions?: Apollo.QueryHookOptions<GetNotifications, GetNotificationsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotifications, GetNotificationsVariables>(GetNotificationsDocument, options);
      }
export function useGetNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotifications, GetNotificationsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotifications, GetNotificationsVariables>(GetNotificationsDocument, options);
        }
export function useGetNotificationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetNotifications, GetNotificationsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetNotifications, GetNotificationsVariables>(GetNotificationsDocument, options);
        }
export type GetNotificationsHookResult = ReturnType<typeof useGetNotifications>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsSuspenseQueryHookResult = ReturnType<typeof useGetNotificationsSuspenseQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotifications, GetNotificationsVariables>;
export const NotificationsAggregateDocument = gql`
    query NotificationsAggregate($where: notifications_bool_exp) {
  notifications_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useNotificationsAggregate__
 *
 * To run a query within a React component, call `useNotificationsAggregate` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsAggregate` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsAggregate({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useNotificationsAggregate(baseOptions?: Apollo.QueryHookOptions<NotificationsAggregate, NotificationsAggregateVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsAggregate, NotificationsAggregateVariables>(NotificationsAggregateDocument, options);
      }
export function useNotificationsAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsAggregate, NotificationsAggregateVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsAggregate, NotificationsAggregateVariables>(NotificationsAggregateDocument, options);
        }
export function useNotificationsAggregateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NotificationsAggregate, NotificationsAggregateVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NotificationsAggregate, NotificationsAggregateVariables>(NotificationsAggregateDocument, options);
        }
export type NotificationsAggregateHookResult = ReturnType<typeof useNotificationsAggregate>;
export type NotificationsAggregateLazyQueryHookResult = ReturnType<typeof useNotificationsAggregateLazyQuery>;
export type NotificationsAggregateSuspenseQueryHookResult = ReturnType<typeof useNotificationsAggregateSuspenseQuery>;
export type NotificationsAggregateQueryResult = Apollo.QueryResult<NotificationsAggregate, NotificationsAggregateVariables>;
export const GetLearnerProfilesDocument = gql`
    query GetLearnerProfiles($where: learner_profiles_bool_exp, $limit: Int) {
  learner_profiles(where: $where, limit: $limit) {
    id
    display_name
    avatar_id
    user {
      id
      phone_number
      email
      subscription_expire_at
      user_name
    }
  }
}
    `;

/**
 * __useGetLearnerProfiles__
 *
 * To run a query within a React component, call `useGetLearnerProfiles` and pass it any options that fit your needs.
 * When your component renders, `useGetLearnerProfiles` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLearnerProfiles({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetLearnerProfiles(baseOptions?: Apollo.QueryHookOptions<GetLearnerProfiles, GetLearnerProfilesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLearnerProfiles, GetLearnerProfilesVariables>(GetLearnerProfilesDocument, options);
      }
export function useGetLearnerProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLearnerProfiles, GetLearnerProfilesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLearnerProfiles, GetLearnerProfilesVariables>(GetLearnerProfilesDocument, options);
        }
export function useGetLearnerProfilesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetLearnerProfiles, GetLearnerProfilesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLearnerProfiles, GetLearnerProfilesVariables>(GetLearnerProfilesDocument, options);
        }
export type GetLearnerProfilesHookResult = ReturnType<typeof useGetLearnerProfiles>;
export type GetLearnerProfilesLazyQueryHookResult = ReturnType<typeof useGetLearnerProfilesLazyQuery>;
export type GetLearnerProfilesSuspenseQueryHookResult = ReturnType<typeof useGetLearnerProfilesSuspenseQuery>;
export type GetLearnerProfilesQueryResult = Apollo.QueryResult<GetLearnerProfiles, GetLearnerProfilesVariables>;
export const GetTeacherProfilesDocument = gql`
    query GetTeacherProfiles($where: teacher_profiles_bool_exp, $limit: Int) {
  teacher_profiles(where: $where, limit: $limit) {
    id
    display_name
    avatar_id
    user {
      id
      phone_number
      email
      subscription_expire_at
      user_name
    }
  }
}
    `;

/**
 * __useGetTeacherProfiles__
 *
 * To run a query within a React component, call `useGetTeacherProfiles` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherProfiles` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherProfiles({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetTeacherProfiles(baseOptions?: Apollo.QueryHookOptions<GetTeacherProfiles, GetTeacherProfilesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeacherProfiles, GetTeacherProfilesVariables>(GetTeacherProfilesDocument, options);
      }
export function useGetTeacherProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeacherProfiles, GetTeacherProfilesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeacherProfiles, GetTeacherProfilesVariables>(GetTeacherProfilesDocument, options);
        }
export function useGetTeacherProfilesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTeacherProfiles, GetTeacherProfilesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTeacherProfiles, GetTeacherProfilesVariables>(GetTeacherProfilesDocument, options);
        }
export type GetTeacherProfilesHookResult = ReturnType<typeof useGetTeacherProfiles>;
export type GetTeacherProfilesLazyQueryHookResult = ReturnType<typeof useGetTeacherProfilesLazyQuery>;
export type GetTeacherProfilesSuspenseQueryHookResult = ReturnType<typeof useGetTeacherProfilesSuspenseQuery>;
export type GetTeacherProfilesQueryResult = Apollo.QueryResult<GetTeacherProfiles, GetTeacherProfilesVariables>;
export const GetStudyTimeDayDocument = gql`
    query GetStudyTimeDay($where: admin_study_time_day_bool_exp) {
  admin_study_time_day(where: $where) {
    date
    total_execution_time
    total_exam_execution_time
    total_mini_execution_time
    total_speaking_execution_time
    total_ket_pet_speaking_execution_time
    total_ket_pet_writing_execution_time
    total_speaking_ielts_execution_time
    total_writing_execution_time
    total_time_mobile
    total_exam_execution_time_child
  }
}
    `;

/**
 * __useGetStudyTimeDay__
 *
 * To run a query within a React component, call `useGetStudyTimeDay` and pass it any options that fit your needs.
 * When your component renders, `useGetStudyTimeDay` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudyTimeDay({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetStudyTimeDay(baseOptions?: Apollo.QueryHookOptions<GetStudyTimeDay, GetStudyTimeDayVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudyTimeDay, GetStudyTimeDayVariables>(GetStudyTimeDayDocument, options);
      }
export function useGetStudyTimeDayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudyTimeDay, GetStudyTimeDayVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudyTimeDay, GetStudyTimeDayVariables>(GetStudyTimeDayDocument, options);
        }
export function useGetStudyTimeDaySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetStudyTimeDay, GetStudyTimeDayVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStudyTimeDay, GetStudyTimeDayVariables>(GetStudyTimeDayDocument, options);
        }
export type GetStudyTimeDayHookResult = ReturnType<typeof useGetStudyTimeDay>;
export type GetStudyTimeDayLazyQueryHookResult = ReturnType<typeof useGetStudyTimeDayLazyQuery>;
export type GetStudyTimeDaySuspenseQueryHookResult = ReturnType<typeof useGetStudyTimeDaySuspenseQuery>;
export type GetStudyTimeDayQueryResult = Apollo.QueryResult<GetStudyTimeDay, GetStudyTimeDayVariables>;
export const GetStudyTimeWeekDocument = gql`
    query GetStudyTimeWeek($where: admin_study_time_week_bool_exp) {
  admin_study_time_week(where: $where) {
    date
    total_execution_time
    total_exam_execution_time
    total_mini_execution_time
    total_speaking_execution_time
    total_ket_pet_speaking_execution_time
    total_ket_pet_writing_execution_time
    total_speaking_ielts_execution_time
    total_writing_execution_time
    total_time_mobile
    total_exam_execution_time_child
  }
}
    `;

/**
 * __useGetStudyTimeWeek__
 *
 * To run a query within a React component, call `useGetStudyTimeWeek` and pass it any options that fit your needs.
 * When your component renders, `useGetStudyTimeWeek` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudyTimeWeek({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetStudyTimeWeek(baseOptions?: Apollo.QueryHookOptions<GetStudyTimeWeek, GetStudyTimeWeekVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudyTimeWeek, GetStudyTimeWeekVariables>(GetStudyTimeWeekDocument, options);
      }
export function useGetStudyTimeWeekLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudyTimeWeek, GetStudyTimeWeekVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudyTimeWeek, GetStudyTimeWeekVariables>(GetStudyTimeWeekDocument, options);
        }
export function useGetStudyTimeWeekSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetStudyTimeWeek, GetStudyTimeWeekVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStudyTimeWeek, GetStudyTimeWeekVariables>(GetStudyTimeWeekDocument, options);
        }
export type GetStudyTimeWeekHookResult = ReturnType<typeof useGetStudyTimeWeek>;
export type GetStudyTimeWeekLazyQueryHookResult = ReturnType<typeof useGetStudyTimeWeekLazyQuery>;
export type GetStudyTimeWeekSuspenseQueryHookResult = ReturnType<typeof useGetStudyTimeWeekSuspenseQuery>;
export type GetStudyTimeWeekQueryResult = Apollo.QueryResult<GetStudyTimeWeek, GetStudyTimeWeekVariables>;
export const GetStudyTimeMonthDocument = gql`
    query GetStudyTimeMonth($where: admin_study_time_month_bool_exp) {
  admin_study_time_month(where: $where) {
    date
    total_execution_time
    total_exam_execution_time
    total_mini_execution_time
    total_speaking_execution_time
    total_ket_pet_speaking_execution_time
    total_ket_pet_writing_execution_time
    total_speaking_ielts_execution_time
    total_writing_execution_time
    total_time_mobile
    total_exam_execution_time_child
  }
}
    `;

/**
 * __useGetStudyTimeMonth__
 *
 * To run a query within a React component, call `useGetStudyTimeMonth` and pass it any options that fit your needs.
 * When your component renders, `useGetStudyTimeMonth` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudyTimeMonth({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetStudyTimeMonth(baseOptions?: Apollo.QueryHookOptions<GetStudyTimeMonth, GetStudyTimeMonthVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudyTimeMonth, GetStudyTimeMonthVariables>(GetStudyTimeMonthDocument, options);
      }
export function useGetStudyTimeMonthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudyTimeMonth, GetStudyTimeMonthVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudyTimeMonth, GetStudyTimeMonthVariables>(GetStudyTimeMonthDocument, options);
        }
export function useGetStudyTimeMonthSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetStudyTimeMonth, GetStudyTimeMonthVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStudyTimeMonth, GetStudyTimeMonthVariables>(GetStudyTimeMonthDocument, options);
        }
export type GetStudyTimeMonthHookResult = ReturnType<typeof useGetStudyTimeMonth>;
export type GetStudyTimeMonthLazyQueryHookResult = ReturnType<typeof useGetStudyTimeMonthLazyQuery>;
export type GetStudyTimeMonthSuspenseQueryHookResult = ReturnType<typeof useGetStudyTimeMonthSuspenseQuery>;
export type GetStudyTimeMonthQueryResult = Apollo.QueryResult<GetStudyTimeMonth, GetStudyTimeMonthVariables>;
export const GetCompletedTestsRateDayDocument = gql`
    query GetCompletedTestsRateDay($where: completed_tests_rate_by_day_bool_exp) {
  completed_tests_rate_by_day(where: $where) {
    date
    rate_premium
    rate_free
    total_completed_tests_premium
    total_completed_tests_free
    total_learner_profiles_premium
    total_learner_profiles_free
    total_completed_tests
    total_learner_profiles
    total_completed_tests_premium_exam
    total_completed_tests_free_exam
    total_completed_tests_premium_mini
    total_completed_tests_free_mini
    total_completed_tests_premium_practice
    total_completed_tests_free_practice
    total_completed_tests_free_placement_test
    total_mobile_completed_tests
    total_practice_mode_pre
    total_practice_mode_free
    total_completed_tests_ket_pet_speaking_pre
    total_completed_tests_ket_pet_speaking_free
    total_completed_tests_ket_pet_writing_pre
    total_completed_tests_ket_pet_writing_free
  }
}
    `;

/**
 * __useGetCompletedTestsRateDay__
 *
 * To run a query within a React component, call `useGetCompletedTestsRateDay` and pass it any options that fit your needs.
 * When your component renders, `useGetCompletedTestsRateDay` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompletedTestsRateDay({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCompletedTestsRateDay(baseOptions?: Apollo.QueryHookOptions<GetCompletedTestsRateDay, GetCompletedTestsRateDayVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompletedTestsRateDay, GetCompletedTestsRateDayVariables>(GetCompletedTestsRateDayDocument, options);
      }
export function useGetCompletedTestsRateDayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompletedTestsRateDay, GetCompletedTestsRateDayVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompletedTestsRateDay, GetCompletedTestsRateDayVariables>(GetCompletedTestsRateDayDocument, options);
        }
export function useGetCompletedTestsRateDaySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCompletedTestsRateDay, GetCompletedTestsRateDayVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCompletedTestsRateDay, GetCompletedTestsRateDayVariables>(GetCompletedTestsRateDayDocument, options);
        }
export type GetCompletedTestsRateDayHookResult = ReturnType<typeof useGetCompletedTestsRateDay>;
export type GetCompletedTestsRateDayLazyQueryHookResult = ReturnType<typeof useGetCompletedTestsRateDayLazyQuery>;
export type GetCompletedTestsRateDaySuspenseQueryHookResult = ReturnType<typeof useGetCompletedTestsRateDaySuspenseQuery>;
export type GetCompletedTestsRateDayQueryResult = Apollo.QueryResult<GetCompletedTestsRateDay, GetCompletedTestsRateDayVariables>;
export const GetCompletedTestsRateWeekDocument = gql`
    query GetCompletedTestsRateWeek($where: completed_tests_rate_by_week_bool_exp) {
  completed_tests_rate_by_week(where: $where) {
    date
    rate_premium
    rate_free
    total_completed_tests_premium
    total_completed_tests_free
    total_learner_profiles_premium
    total_learner_profiles_free
    total_completed_tests
    total_learner_profiles
    total_completed_tests_premium_exam
    total_completed_tests_free_exam
    total_completed_tests_premium_mini
    total_completed_tests_free_mini
    total_completed_tests_premium_practice
    total_completed_tests_free_practice
    total_completed_tests_free_placement_test
    total_mobile_completed_tests
    total_practice_mode_pre
    total_practice_mode_free
    total_completed_tests_ket_pet_speaking_pre
    total_completed_tests_ket_pet_speaking_free
    total_completed_tests_ket_pet_writing_pre
    total_completed_tests_ket_pet_writing_free
  }
}
    `;

/**
 * __useGetCompletedTestsRateWeek__
 *
 * To run a query within a React component, call `useGetCompletedTestsRateWeek` and pass it any options that fit your needs.
 * When your component renders, `useGetCompletedTestsRateWeek` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompletedTestsRateWeek({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCompletedTestsRateWeek(baseOptions?: Apollo.QueryHookOptions<GetCompletedTestsRateWeek, GetCompletedTestsRateWeekVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompletedTestsRateWeek, GetCompletedTestsRateWeekVariables>(GetCompletedTestsRateWeekDocument, options);
      }
export function useGetCompletedTestsRateWeekLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompletedTestsRateWeek, GetCompletedTestsRateWeekVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompletedTestsRateWeek, GetCompletedTestsRateWeekVariables>(GetCompletedTestsRateWeekDocument, options);
        }
export function useGetCompletedTestsRateWeekSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCompletedTestsRateWeek, GetCompletedTestsRateWeekVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCompletedTestsRateWeek, GetCompletedTestsRateWeekVariables>(GetCompletedTestsRateWeekDocument, options);
        }
export type GetCompletedTestsRateWeekHookResult = ReturnType<typeof useGetCompletedTestsRateWeek>;
export type GetCompletedTestsRateWeekLazyQueryHookResult = ReturnType<typeof useGetCompletedTestsRateWeekLazyQuery>;
export type GetCompletedTestsRateWeekSuspenseQueryHookResult = ReturnType<typeof useGetCompletedTestsRateWeekSuspenseQuery>;
export type GetCompletedTestsRateWeekQueryResult = Apollo.QueryResult<GetCompletedTestsRateWeek, GetCompletedTestsRateWeekVariables>;
export const GetCompletedTestsRateMonthDocument = gql`
    query GetCompletedTestsRateMonth($where: completed_tests_rate_by_month_bool_exp) {
  completed_tests_rate_by_month(where: $where) {
    date
    rate_premium
    rate_free
    total_completed_tests_premium
    total_completed_tests_free
    total_learner_profiles_premium
    total_learner_profiles_free
    total_completed_tests
    total_learner_profiles
    total_completed_tests_premium_exam
    total_completed_tests_free_exam
    total_completed_tests_premium_mini
    total_completed_tests_free_mini
    total_completed_tests_premium_practice
    total_completed_tests_free_practice
    total_completed_tests_free_placement_test
    total_mobile_completed_tests
    total_practice_mode_pre
    total_practice_mode_free
    total_completed_tests_ket_pet_speaking_pre
    total_completed_tests_ket_pet_speaking_free
    total_completed_tests_ket_pet_writing_pre
    total_completed_tests_ket_pet_writing_free
  }
}
    `;

/**
 * __useGetCompletedTestsRateMonth__
 *
 * To run a query within a React component, call `useGetCompletedTestsRateMonth` and pass it any options that fit your needs.
 * When your component renders, `useGetCompletedTestsRateMonth` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompletedTestsRateMonth({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCompletedTestsRateMonth(baseOptions?: Apollo.QueryHookOptions<GetCompletedTestsRateMonth, GetCompletedTestsRateMonthVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompletedTestsRateMonth, GetCompletedTestsRateMonthVariables>(GetCompletedTestsRateMonthDocument, options);
      }
export function useGetCompletedTestsRateMonthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompletedTestsRateMonth, GetCompletedTestsRateMonthVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompletedTestsRateMonth, GetCompletedTestsRateMonthVariables>(GetCompletedTestsRateMonthDocument, options);
        }
export function useGetCompletedTestsRateMonthSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCompletedTestsRateMonth, GetCompletedTestsRateMonthVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCompletedTestsRateMonth, GetCompletedTestsRateMonthVariables>(GetCompletedTestsRateMonthDocument, options);
        }
export type GetCompletedTestsRateMonthHookResult = ReturnType<typeof useGetCompletedTestsRateMonth>;
export type GetCompletedTestsRateMonthLazyQueryHookResult = ReturnType<typeof useGetCompletedTestsRateMonthLazyQuery>;
export type GetCompletedTestsRateMonthSuspenseQueryHookResult = ReturnType<typeof useGetCompletedTestsRateMonthSuspenseQuery>;
export type GetCompletedTestsRateMonthQueryResult = Apollo.QueryResult<GetCompletedTestsRateMonth, GetCompletedTestsRateMonthVariables>;
export const GetMembershipDocument = gql`
    query getMembership($date: timestamp_comparison_exp) {
  trial: trial_by_day(where: {date: $date}) {
    count
    date
    source_counts
  }
  membership: membership_by_day(where: {date: $date}) {
    codeTypeCount: code_type_count
    date
  }
}
    `;

/**
 * __useGetMembership__
 *
 * To run a query within a React component, call `useGetMembership` and pass it any options that fit your needs.
 * When your component renders, `useGetMembership` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembership({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetMembership(baseOptions?: Apollo.QueryHookOptions<GetMembership, GetMembershipVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMembership, GetMembershipVariables>(GetMembershipDocument, options);
      }
export function useGetMembershipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMembership, GetMembershipVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMembership, GetMembershipVariables>(GetMembershipDocument, options);
        }
export function useGetMembershipSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMembership, GetMembershipVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMembership, GetMembershipVariables>(GetMembershipDocument, options);
        }
export type GetMembershipHookResult = ReturnType<typeof useGetMembership>;
export type GetMembershipLazyQueryHookResult = ReturnType<typeof useGetMembershipLazyQuery>;
export type GetMembershipSuspenseQueryHookResult = ReturnType<typeof useGetMembershipSuspenseQuery>;
export type GetMembershipQueryResult = Apollo.QueryResult<GetMembership, GetMembershipVariables>;
export const GetUsersDocument = gql`
    query getUsers($where: users_bool_exp, $offset: Int, $limit: Int, $order_by: [users_order_by!]) {
  users(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
    id
    email
    phone_number
    email_verified
    subscription_expire_at
    meta
    created_at
    source_domain
    utm_source
    utm_campaign
    utm_content
    utm_medium
    teacher_verified
    diamond
    user_name
    activation_codes {
      activation_code {
        code
        discount {
          id
          code
        }
        flyer_package {
          name
          id
        }
      }
    }
    teacher_profile {
      about
      avatar_id
      created_at
      display_name
      gender
      id
    }
    learner_profiles(order_by: {created_at: asc}) {
      id
      display_name
      photo_url: avatar_id
      date_of_birth
      gender
      user_attempts_aggregate(where: {submitted_at: {_is_null: false}}) {
        aggregate {
          count
        }
      }
      school {
        id
        name
      }
      classes {
        class_id
        class {
          code
          school {
            id
            name
          }
        }
      }
    }
  }
  users_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetUsers__
 *
 * To run a query within a React component, call `useGetUsers` and pass it any options that fit your needs.
 * When your component renders, `useGetUsers` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsers({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetUsers(baseOptions?: Apollo.QueryHookOptions<GetUsers, GetUsersVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsers, GetUsersVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsers, GetUsersVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsers, GetUsersVariables>(GetUsersDocument, options);
        }
export function useGetUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsers, GetUsersVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsers, GetUsersVariables>(GetUsersDocument, options);
        }
export type GetUsersHookResult = ReturnType<typeof useGetUsers>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersSuspenseQueryHookResult = ReturnType<typeof useGetUsersSuspenseQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsers, GetUsersVariables>;
export const GetLearnerProfileCountDocument = gql`
    query getLearnerProfileCount($where: learner_profiles_bool_exp) {
  learner_profiles_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetLearnerProfileCount__
 *
 * To run a query within a React component, call `useGetLearnerProfileCount` and pass it any options that fit your needs.
 * When your component renders, `useGetLearnerProfileCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLearnerProfileCount({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetLearnerProfileCount(baseOptions?: Apollo.QueryHookOptions<GetLearnerProfileCount, GetLearnerProfileCountVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLearnerProfileCount, GetLearnerProfileCountVariables>(GetLearnerProfileCountDocument, options);
      }
export function useGetLearnerProfileCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLearnerProfileCount, GetLearnerProfileCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLearnerProfileCount, GetLearnerProfileCountVariables>(GetLearnerProfileCountDocument, options);
        }
export function useGetLearnerProfileCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetLearnerProfileCount, GetLearnerProfileCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLearnerProfileCount, GetLearnerProfileCountVariables>(GetLearnerProfileCountDocument, options);
        }
export type GetLearnerProfileCountHookResult = ReturnType<typeof useGetLearnerProfileCount>;
export type GetLearnerProfileCountLazyQueryHookResult = ReturnType<typeof useGetLearnerProfileCountLazyQuery>;
export type GetLearnerProfileCountSuspenseQueryHookResult = ReturnType<typeof useGetLearnerProfileCountSuspenseQuery>;
export type GetLearnerProfileCountQueryResult = Apollo.QueryResult<GetLearnerProfileCount, GetLearnerProfileCountVariables>;
export const GetTestUndoneDayDocument = gql`
    query GetTestUndoneDay($where: test_undone_day_bool_exp, $limit: Int, $offset: Int) {
  test_undone_day(where: $where, limit: $limit, offset: $offset) {
    meta
    total
    date
  }
}
    `;

/**
 * __useGetTestUndoneDay__
 *
 * To run a query within a React component, call `useGetTestUndoneDay` and pass it any options that fit your needs.
 * When your component renders, `useGetTestUndoneDay` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTestUndoneDay({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetTestUndoneDay(baseOptions?: Apollo.QueryHookOptions<GetTestUndoneDay, GetTestUndoneDayVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTestUndoneDay, GetTestUndoneDayVariables>(GetTestUndoneDayDocument, options);
      }
export function useGetTestUndoneDayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTestUndoneDay, GetTestUndoneDayVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTestUndoneDay, GetTestUndoneDayVariables>(GetTestUndoneDayDocument, options);
        }
export function useGetTestUndoneDaySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTestUndoneDay, GetTestUndoneDayVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTestUndoneDay, GetTestUndoneDayVariables>(GetTestUndoneDayDocument, options);
        }
export type GetTestUndoneDayHookResult = ReturnType<typeof useGetTestUndoneDay>;
export type GetTestUndoneDayLazyQueryHookResult = ReturnType<typeof useGetTestUndoneDayLazyQuery>;
export type GetTestUndoneDaySuspenseQueryHookResult = ReturnType<typeof useGetTestUndoneDaySuspenseQuery>;
export type GetTestUndoneDayQueryResult = Apollo.QueryResult<GetTestUndoneDay, GetTestUndoneDayVariables>;
export const GetUserFreeToMembershipDocument = gql`
    query GetUserFreeToMembership {
  user_free_to_membership {
    avg
  }
}
    `;

/**
 * __useGetUserFreeToMembership__
 *
 * To run a query within a React component, call `useGetUserFreeToMembership` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFreeToMembership` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFreeToMembership({
 *   variables: {
 *   },
 * });
 */
export function useGetUserFreeToMembership(baseOptions?: Apollo.QueryHookOptions<GetUserFreeToMembership, GetUserFreeToMembershipVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserFreeToMembership, GetUserFreeToMembershipVariables>(GetUserFreeToMembershipDocument, options);
      }
export function useGetUserFreeToMembershipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserFreeToMembership, GetUserFreeToMembershipVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserFreeToMembership, GetUserFreeToMembershipVariables>(GetUserFreeToMembershipDocument, options);
        }
export function useGetUserFreeToMembershipSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserFreeToMembership, GetUserFreeToMembershipVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserFreeToMembership, GetUserFreeToMembershipVariables>(GetUserFreeToMembershipDocument, options);
        }
export type GetUserFreeToMembershipHookResult = ReturnType<typeof useGetUserFreeToMembership>;
export type GetUserFreeToMembershipLazyQueryHookResult = ReturnType<typeof useGetUserFreeToMembershipLazyQuery>;
export type GetUserFreeToMembershipSuspenseQueryHookResult = ReturnType<typeof useGetUserFreeToMembershipSuspenseQuery>;
export type GetUserFreeToMembershipQueryResult = Apollo.QueryResult<GetUserFreeToMembership, GetUserFreeToMembershipVariables>;
export const GetFeatureFlagDocument = gql`
    query GetFeatureFlag($where: feature_flag_bool_exp, $limit: Int, $offset: Int, $order_by: [feature_flag_order_by!]) {
  feature_flag(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
    id
    feature
    user_ids
    domains
    class_ids
    teacher_ids
  }
}
    `;

/**
 * __useGetFeatureFlag__
 *
 * To run a query within a React component, call `useGetFeatureFlag` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureFlag` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureFlag({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetFeatureFlag(baseOptions?: Apollo.QueryHookOptions<GetFeatureFlag, GetFeatureFlagVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeatureFlag, GetFeatureFlagVariables>(GetFeatureFlagDocument, options);
      }
export function useGetFeatureFlagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeatureFlag, GetFeatureFlagVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeatureFlag, GetFeatureFlagVariables>(GetFeatureFlagDocument, options);
        }
export function useGetFeatureFlagSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetFeatureFlag, GetFeatureFlagVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetFeatureFlag, GetFeatureFlagVariables>(GetFeatureFlagDocument, options);
        }
export type GetFeatureFlagHookResult = ReturnType<typeof useGetFeatureFlag>;
export type GetFeatureFlagLazyQueryHookResult = ReturnType<typeof useGetFeatureFlagLazyQuery>;
export type GetFeatureFlagSuspenseQueryHookResult = ReturnType<typeof useGetFeatureFlagSuspenseQuery>;
export type GetFeatureFlagQueryResult = Apollo.QueryResult<GetFeatureFlag, GetFeatureFlagVariables>;
export const GetFeatureFlagAggregateDocument = gql`
    query GetFeatureFlagAggregate($where: feature_flag_bool_exp) {
  feature_flag_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetFeatureFlagAggregate__
 *
 * To run a query within a React component, call `useGetFeatureFlagAggregate` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureFlagAggregate` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureFlagAggregate({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetFeatureFlagAggregate(baseOptions?: Apollo.QueryHookOptions<GetFeatureFlagAggregate, GetFeatureFlagAggregateVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeatureFlagAggregate, GetFeatureFlagAggregateVariables>(GetFeatureFlagAggregateDocument, options);
      }
export function useGetFeatureFlagAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeatureFlagAggregate, GetFeatureFlagAggregateVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeatureFlagAggregate, GetFeatureFlagAggregateVariables>(GetFeatureFlagAggregateDocument, options);
        }
export function useGetFeatureFlagAggregateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetFeatureFlagAggregate, GetFeatureFlagAggregateVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetFeatureFlagAggregate, GetFeatureFlagAggregateVariables>(GetFeatureFlagAggregateDocument, options);
        }
export type GetFeatureFlagAggregateHookResult = ReturnType<typeof useGetFeatureFlagAggregate>;
export type GetFeatureFlagAggregateLazyQueryHookResult = ReturnType<typeof useGetFeatureFlagAggregateLazyQuery>;
export type GetFeatureFlagAggregateSuspenseQueryHookResult = ReturnType<typeof useGetFeatureFlagAggregateSuspenseQuery>;
export type GetFeatureFlagAggregateQueryResult = Apollo.QueryResult<GetFeatureFlagAggregate, GetFeatureFlagAggregateVariables>;
export const GetClassesDocument = gql`
    query GetClasses($where: classes_bool_exp, $limit: Int) {
  classes(where: $where, limit: $limit) {
    id
    code
    name
  }
}
    `;

/**
 * __useGetClasses__
 *
 * To run a query within a React component, call `useGetClasses` and pass it any options that fit your needs.
 * When your component renders, `useGetClasses` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClasses({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetClasses(baseOptions?: Apollo.QueryHookOptions<GetClasses, GetClassesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClasses, GetClassesVariables>(GetClassesDocument, options);
      }
export function useGetClassesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClasses, GetClassesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClasses, GetClassesVariables>(GetClassesDocument, options);
        }
export function useGetClassesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetClasses, GetClassesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetClasses, GetClassesVariables>(GetClassesDocument, options);
        }
export type GetClassesHookResult = ReturnType<typeof useGetClasses>;
export type GetClassesLazyQueryHookResult = ReturnType<typeof useGetClassesLazyQuery>;
export type GetClassesSuspenseQueryHookResult = ReturnType<typeof useGetClassesSuspenseQuery>;
export type GetClassesQueryResult = Apollo.QueryResult<GetClasses, GetClassesVariables>;
export const GetCustomDomainDocument = gql`
    query GetCustomDomain($where: custom_domain_bool_exp) {
  custom_domain(where: $where) {
    id
    domain_name
  }
}
    `;

/**
 * __useGetCustomDomain__
 *
 * To run a query within a React component, call `useGetCustomDomain` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomDomain` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomDomain({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCustomDomain(baseOptions?: Apollo.QueryHookOptions<GetCustomDomain, GetCustomDomainVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomDomain, GetCustomDomainVariables>(GetCustomDomainDocument, options);
      }
export function useGetCustomDomainLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomDomain, GetCustomDomainVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomDomain, GetCustomDomainVariables>(GetCustomDomainDocument, options);
        }
export function useGetCustomDomainSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCustomDomain, GetCustomDomainVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCustomDomain, GetCustomDomainVariables>(GetCustomDomainDocument, options);
        }
export type GetCustomDomainHookResult = ReturnType<typeof useGetCustomDomain>;
export type GetCustomDomainLazyQueryHookResult = ReturnType<typeof useGetCustomDomainLazyQuery>;
export type GetCustomDomainSuspenseQueryHookResult = ReturnType<typeof useGetCustomDomainSuspenseQuery>;
export type GetCustomDomainQueryResult = Apollo.QueryResult<GetCustomDomain, GetCustomDomainVariables>;
export const ActivationPlansDocument = gql`
    query ActivationPlans($where: plans_bool_exp) {
  plans(where: $where) {
    id
    day
    name
  }
}
    `;

/**
 * __useActivationPlans__
 *
 * To run a query within a React component, call `useActivationPlans` and pass it any options that fit your needs.
 * When your component renders, `useActivationPlans` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivationPlans({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useActivationPlans(baseOptions?: Apollo.QueryHookOptions<ActivationPlans, ActivationPlansVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActivationPlans, ActivationPlansVariables>(ActivationPlansDocument, options);
      }
export function useActivationPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivationPlans, ActivationPlansVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActivationPlans, ActivationPlansVariables>(ActivationPlansDocument, options);
        }
export function useActivationPlansSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ActivationPlans, ActivationPlansVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ActivationPlans, ActivationPlansVariables>(ActivationPlansDocument, options);
        }
export type ActivationPlansHookResult = ReturnType<typeof useActivationPlans>;
export type ActivationPlansLazyQueryHookResult = ReturnType<typeof useActivationPlansLazyQuery>;
export type ActivationPlansSuspenseQueryHookResult = ReturnType<typeof useActivationPlansSuspenseQuery>;
export type ActivationPlansQueryResult = Apollo.QueryResult<ActivationPlans, ActivationPlansVariables>;
export const GetCheckpointSpeakingPartsCountDocument = gql`
    query getCheckpointSpeakingPartsCount($where: check_point_speaking_parts_bool_exp) {
  check_point_speaking_parts_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetCheckpointSpeakingPartsCount__
 *
 * To run a query within a React component, call `useGetCheckpointSpeakingPartsCount` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckpointSpeakingPartsCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckpointSpeakingPartsCount({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCheckpointSpeakingPartsCount(baseOptions?: Apollo.QueryHookOptions<GetCheckpointSpeakingPartsCount, GetCheckpointSpeakingPartsCountVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCheckpointSpeakingPartsCount, GetCheckpointSpeakingPartsCountVariables>(GetCheckpointSpeakingPartsCountDocument, options);
      }
export function useGetCheckpointSpeakingPartsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCheckpointSpeakingPartsCount, GetCheckpointSpeakingPartsCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCheckpointSpeakingPartsCount, GetCheckpointSpeakingPartsCountVariables>(GetCheckpointSpeakingPartsCountDocument, options);
        }
export function useGetCheckpointSpeakingPartsCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCheckpointSpeakingPartsCount, GetCheckpointSpeakingPartsCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCheckpointSpeakingPartsCount, GetCheckpointSpeakingPartsCountVariables>(GetCheckpointSpeakingPartsCountDocument, options);
        }
export type GetCheckpointSpeakingPartsCountHookResult = ReturnType<typeof useGetCheckpointSpeakingPartsCount>;
export type GetCheckpointSpeakingPartsCountLazyQueryHookResult = ReturnType<typeof useGetCheckpointSpeakingPartsCountLazyQuery>;
export type GetCheckpointSpeakingPartsCountSuspenseQueryHookResult = ReturnType<typeof useGetCheckpointSpeakingPartsCountSuspenseQuery>;
export type GetCheckpointSpeakingPartsCountQueryResult = Apollo.QueryResult<GetCheckpointSpeakingPartsCount, GetCheckpointSpeakingPartsCountVariables>;
export const GetCheckPointSpeakingPartDocument = gql`
    query GetCheckPointSpeakingPart($where: check_point_speaking_parts_bool_exp, $limit: Int, $offset: Int) {
  check_point_speaking_parts(where: $where, limit: $limit, offset: $offset) {
    ...SpeakingCheckpointPart
  }
}
    ${SpeakingCheckpointPart}`;

/**
 * __useGetCheckPointSpeakingPart__
 *
 * To run a query within a React component, call `useGetCheckPointSpeakingPart` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckPointSpeakingPart` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckPointSpeakingPart({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetCheckPointSpeakingPart(baseOptions?: Apollo.QueryHookOptions<GetCheckPointSpeakingPart, GetCheckPointSpeakingPartVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCheckPointSpeakingPart, GetCheckPointSpeakingPartVariables>(GetCheckPointSpeakingPartDocument, options);
      }
export function useGetCheckPointSpeakingPartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCheckPointSpeakingPart, GetCheckPointSpeakingPartVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCheckPointSpeakingPart, GetCheckPointSpeakingPartVariables>(GetCheckPointSpeakingPartDocument, options);
        }
export function useGetCheckPointSpeakingPartSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCheckPointSpeakingPart, GetCheckPointSpeakingPartVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCheckPointSpeakingPart, GetCheckPointSpeakingPartVariables>(GetCheckPointSpeakingPartDocument, options);
        }
export type GetCheckPointSpeakingPartHookResult = ReturnType<typeof useGetCheckPointSpeakingPart>;
export type GetCheckPointSpeakingPartLazyQueryHookResult = ReturnType<typeof useGetCheckPointSpeakingPartLazyQuery>;
export type GetCheckPointSpeakingPartSuspenseQueryHookResult = ReturnType<typeof useGetCheckPointSpeakingPartSuspenseQuery>;
export type GetCheckPointSpeakingPartQueryResult = Apollo.QueryResult<GetCheckPointSpeakingPart, GetCheckPointSpeakingPartVariables>;
export const GetListPartsDocument = gql`
    query getListParts {
  listParts: check_point_speaking_parts(
    order_by: {order: asc}
    distinct_on: order
  ) {
    order
  }
}
    `;

/**
 * __useGetListParts__
 *
 * To run a query within a React component, call `useGetListParts` and pass it any options that fit your needs.
 * When your component renders, `useGetListParts` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListParts({
 *   variables: {
 *   },
 * });
 */
export function useGetListParts(baseOptions?: Apollo.QueryHookOptions<GetListParts, GetListPartsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListParts, GetListPartsVariables>(GetListPartsDocument, options);
      }
export function useGetListPartsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListParts, GetListPartsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListParts, GetListPartsVariables>(GetListPartsDocument, options);
        }
export function useGetListPartsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetListParts, GetListPartsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetListParts, GetListPartsVariables>(GetListPartsDocument, options);
        }
export type GetListPartsHookResult = ReturnType<typeof useGetListParts>;
export type GetListPartsLazyQueryHookResult = ReturnType<typeof useGetListPartsLazyQuery>;
export type GetListPartsSuspenseQueryHookResult = ReturnType<typeof useGetListPartsSuspenseQuery>;
export type GetListPartsQueryResult = Apollo.QueryResult<GetListParts, GetListPartsVariables>;
export const GetListPermissionsDocument = gql`
    query getListPermissions {
  partner_permissions {
    id
    value
  }
}
    `;

/**
 * __useGetListPermissions__
 *
 * To run a query within a React component, call `useGetListPermissions` and pass it any options that fit your needs.
 * When your component renders, `useGetListPermissions` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListPermissions({
 *   variables: {
 *   },
 * });
 */
export function useGetListPermissions(baseOptions?: Apollo.QueryHookOptions<GetListPermissions, GetListPermissionsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListPermissions, GetListPermissionsVariables>(GetListPermissionsDocument, options);
      }
export function useGetListPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListPermissions, GetListPermissionsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListPermissions, GetListPermissionsVariables>(GetListPermissionsDocument, options);
        }
export function useGetListPermissionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetListPermissions, GetListPermissionsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetListPermissions, GetListPermissionsVariables>(GetListPermissionsDocument, options);
        }
export type GetListPermissionsHookResult = ReturnType<typeof useGetListPermissions>;
export type GetListPermissionsLazyQueryHookResult = ReturnType<typeof useGetListPermissionsLazyQuery>;
export type GetListPermissionsSuspenseQueryHookResult = ReturnType<typeof useGetListPermissionsSuspenseQuery>;
export type GetListPermissionsQueryResult = Apollo.QueryResult<GetListPermissions, GetListPermissionsVariables>;
export const GetActivationCodeUsersDocument = gql`
    query GetActivationCodeUsers($where: activation_code_users_bool_exp, $limit: Int, $offset: Int) {
  activation_code_users(
    where: $where
    limit: $limit
    offset: $offset
    order_by: {activated_at: desc}
  ) {
    code
    user_id
    activated_at
    id
    user {
      id
      email
      phone_number
      diamond
      user_name
    }
  }
}
    `;

/**
 * __useGetActivationCodeUsers__
 *
 * To run a query within a React component, call `useGetActivationCodeUsers` and pass it any options that fit your needs.
 * When your component renders, `useGetActivationCodeUsers` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivationCodeUsers({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetActivationCodeUsers(baseOptions?: Apollo.QueryHookOptions<GetActivationCodeUsers, GetActivationCodeUsersVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivationCodeUsers, GetActivationCodeUsersVariables>(GetActivationCodeUsersDocument, options);
      }
export function useGetActivationCodeUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivationCodeUsers, GetActivationCodeUsersVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivationCodeUsers, GetActivationCodeUsersVariables>(GetActivationCodeUsersDocument, options);
        }
export function useGetActivationCodeUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetActivationCodeUsers, GetActivationCodeUsersVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetActivationCodeUsers, GetActivationCodeUsersVariables>(GetActivationCodeUsersDocument, options);
        }
export type GetActivationCodeUsersHookResult = ReturnType<typeof useGetActivationCodeUsers>;
export type GetActivationCodeUsersLazyQueryHookResult = ReturnType<typeof useGetActivationCodeUsersLazyQuery>;
export type GetActivationCodeUsersSuspenseQueryHookResult = ReturnType<typeof useGetActivationCodeUsersSuspenseQuery>;
export type GetActivationCodeUsersQueryResult = Apollo.QueryResult<GetActivationCodeUsers, GetActivationCodeUsersVariables>;
export const GetActivationCodeUsersCountDocument = gql`
    query GetActivationCodeUsersCount($where: activation_code_users_bool_exp) {
  activation_code_users_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetActivationCodeUsersCount__
 *
 * To run a query within a React component, call `useGetActivationCodeUsersCount` and pass it any options that fit your needs.
 * When your component renders, `useGetActivationCodeUsersCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivationCodeUsersCount({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetActivationCodeUsersCount(baseOptions?: Apollo.QueryHookOptions<GetActivationCodeUsersCount, GetActivationCodeUsersCountVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivationCodeUsersCount, GetActivationCodeUsersCountVariables>(GetActivationCodeUsersCountDocument, options);
      }
export function useGetActivationCodeUsersCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivationCodeUsersCount, GetActivationCodeUsersCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivationCodeUsersCount, GetActivationCodeUsersCountVariables>(GetActivationCodeUsersCountDocument, options);
        }
export function useGetActivationCodeUsersCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetActivationCodeUsersCount, GetActivationCodeUsersCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetActivationCodeUsersCount, GetActivationCodeUsersCountVariables>(GetActivationCodeUsersCountDocument, options);
        }
export type GetActivationCodeUsersCountHookResult = ReturnType<typeof useGetActivationCodeUsersCount>;
export type GetActivationCodeUsersCountLazyQueryHookResult = ReturnType<typeof useGetActivationCodeUsersCountLazyQuery>;
export type GetActivationCodeUsersCountSuspenseQueryHookResult = ReturnType<typeof useGetActivationCodeUsersCountSuspenseQuery>;
export type GetActivationCodeUsersCountQueryResult = Apollo.QueryResult<GetActivationCodeUsersCount, GetActivationCodeUsersCountVariables>;
export const GetActivationCodesDocument = gql`
    query GetActivationCodes($where: activation_codes_bool_exp, $limit: Int, $offset: Int, $order_by: [activation_codes_order_by!]) {
  activation_codes(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    discount_id
    is_deleted
    diamond
    max_uses
    uses
    plan_day
    code
    type
    activated_at
    created_at
    expired_at
    updated_at
    description
    flyer_package {
      id
      name
    }
    activation_users {
      code
      user_id
      activated_at
      id
      activation_code {
        flyer_package {
          name
          id
        }
      }
      user {
        id
        email
        phone_number
        user_name
        subscription_expire_at
        diamond
      }
    }
  }
}
    `;

/**
 * __useGetActivationCodes__
 *
 * To run a query within a React component, call `useGetActivationCodes` and pass it any options that fit your needs.
 * When your component renders, `useGetActivationCodes` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivationCodes({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetActivationCodes(baseOptions?: Apollo.QueryHookOptions<GetActivationCodes, GetActivationCodesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivationCodes, GetActivationCodesVariables>(GetActivationCodesDocument, options);
      }
export function useGetActivationCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivationCodes, GetActivationCodesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivationCodes, GetActivationCodesVariables>(GetActivationCodesDocument, options);
        }
export function useGetActivationCodesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetActivationCodes, GetActivationCodesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetActivationCodes, GetActivationCodesVariables>(GetActivationCodesDocument, options);
        }
export type GetActivationCodesHookResult = ReturnType<typeof useGetActivationCodes>;
export type GetActivationCodesLazyQueryHookResult = ReturnType<typeof useGetActivationCodesLazyQuery>;
export type GetActivationCodesSuspenseQueryHookResult = ReturnType<typeof useGetActivationCodesSuspenseQuery>;
export type GetActivationCodesQueryResult = Apollo.QueryResult<GetActivationCodes, GetActivationCodesVariables>;
export const GetActivationCodesCountDocument = gql`
    query GetActivationCodesCount($where: activation_codes_bool_exp) {
  activation_codes_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetActivationCodesCount__
 *
 * To run a query within a React component, call `useGetActivationCodesCount` and pass it any options that fit your needs.
 * When your component renders, `useGetActivationCodesCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivationCodesCount({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetActivationCodesCount(baseOptions?: Apollo.QueryHookOptions<GetActivationCodesCount, GetActivationCodesCountVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivationCodesCount, GetActivationCodesCountVariables>(GetActivationCodesCountDocument, options);
      }
export function useGetActivationCodesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivationCodesCount, GetActivationCodesCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivationCodesCount, GetActivationCodesCountVariables>(GetActivationCodesCountDocument, options);
        }
export function useGetActivationCodesCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetActivationCodesCount, GetActivationCodesCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetActivationCodesCount, GetActivationCodesCountVariables>(GetActivationCodesCountDocument, options);
        }
export type GetActivationCodesCountHookResult = ReturnType<typeof useGetActivationCodesCount>;
export type GetActivationCodesCountLazyQueryHookResult = ReturnType<typeof useGetActivationCodesCountLazyQuery>;
export type GetActivationCodesCountSuspenseQueryHookResult = ReturnType<typeof useGetActivationCodesCountSuspenseQuery>;
export type GetActivationCodesCountQueryResult = Apollo.QueryResult<GetActivationCodesCount, GetActivationCodesCountVariables>;
export const GetPackagesDocument = gql`
    query GetPackages($limit: Int, $offset: Int, $order_by: [plans_order_by!]) {
  plans(limit: $limit, offset: $offset, order_by: $order_by) {
    day
    name
    price
    is_showing
    description
    created_at
    updated_at
    id
    position
  }
}
    `;

/**
 * __useGetPackages__
 *
 * To run a query within a React component, call `useGetPackages` and pass it any options that fit your needs.
 * When your component renders, `useGetPackages` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPackages({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetPackages(baseOptions?: Apollo.QueryHookOptions<GetPackages, GetPackagesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPackages, GetPackagesVariables>(GetPackagesDocument, options);
      }
export function useGetPackagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPackages, GetPackagesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPackages, GetPackagesVariables>(GetPackagesDocument, options);
        }
export function useGetPackagesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPackages, GetPackagesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPackages, GetPackagesVariables>(GetPackagesDocument, options);
        }
export type GetPackagesHookResult = ReturnType<typeof useGetPackages>;
export type GetPackagesLazyQueryHookResult = ReturnType<typeof useGetPackagesLazyQuery>;
export type GetPackagesSuspenseQueryHookResult = ReturnType<typeof useGetPackagesSuspenseQuery>;
export type GetPackagesQueryResult = Apollo.QueryResult<GetPackages, GetPackagesVariables>;
export const GetPackagesCountDocument = gql`
    query GetPackagesCount {
  plans_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetPackagesCount__
 *
 * To run a query within a React component, call `useGetPackagesCount` and pass it any options that fit your needs.
 * When your component renders, `useGetPackagesCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPackagesCount({
 *   variables: {
 *   },
 * });
 */
export function useGetPackagesCount(baseOptions?: Apollo.QueryHookOptions<GetPackagesCount, GetPackagesCountVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPackagesCount, GetPackagesCountVariables>(GetPackagesCountDocument, options);
      }
export function useGetPackagesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPackagesCount, GetPackagesCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPackagesCount, GetPackagesCountVariables>(GetPackagesCountDocument, options);
        }
export function useGetPackagesCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPackagesCount, GetPackagesCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPackagesCount, GetPackagesCountVariables>(GetPackagesCountDocument, options);
        }
export type GetPackagesCountHookResult = ReturnType<typeof useGetPackagesCount>;
export type GetPackagesCountLazyQueryHookResult = ReturnType<typeof useGetPackagesCountLazyQuery>;
export type GetPackagesCountSuspenseQueryHookResult = ReturnType<typeof useGetPackagesCountSuspenseQuery>;
export type GetPackagesCountQueryResult = Apollo.QueryResult<GetPackagesCount, GetPackagesCountVariables>;
export const GetActivationCodeTypeDocument = gql`
    query GetActivationCodeType {
  activation_code_type {
    value
  }
}
    `;

/**
 * __useGetActivationCodeType__
 *
 * To run a query within a React component, call `useGetActivationCodeType` and pass it any options that fit your needs.
 * When your component renders, `useGetActivationCodeType` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivationCodeType({
 *   variables: {
 *   },
 * });
 */
export function useGetActivationCodeType(baseOptions?: Apollo.QueryHookOptions<GetActivationCodeType, GetActivationCodeTypeVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivationCodeType, GetActivationCodeTypeVariables>(GetActivationCodeTypeDocument, options);
      }
export function useGetActivationCodeTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivationCodeType, GetActivationCodeTypeVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivationCodeType, GetActivationCodeTypeVariables>(GetActivationCodeTypeDocument, options);
        }
export function useGetActivationCodeTypeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetActivationCodeType, GetActivationCodeTypeVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetActivationCodeType, GetActivationCodeTypeVariables>(GetActivationCodeTypeDocument, options);
        }
export type GetActivationCodeTypeHookResult = ReturnType<typeof useGetActivationCodeType>;
export type GetActivationCodeTypeLazyQueryHookResult = ReturnType<typeof useGetActivationCodeTypeLazyQuery>;
export type GetActivationCodeTypeSuspenseQueryHookResult = ReturnType<typeof useGetActivationCodeTypeSuspenseQuery>;
export type GetActivationCodeTypeQueryResult = Apollo.QueryResult<GetActivationCodeType, GetActivationCodeTypeVariables>;
export const GetTestLevelsCountDocument = gql`
    query GetTestLevelsCount {
  test_levels_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetTestLevelsCount__
 *
 * To run a query within a React component, call `useGetTestLevelsCount` and pass it any options that fit your needs.
 * When your component renders, `useGetTestLevelsCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTestLevelsCount({
 *   variables: {
 *   },
 * });
 */
export function useGetTestLevelsCount(baseOptions?: Apollo.QueryHookOptions<GetTestLevelsCount, GetTestLevelsCountVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTestLevelsCount, GetTestLevelsCountVariables>(GetTestLevelsCountDocument, options);
      }
export function useGetTestLevelsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTestLevelsCount, GetTestLevelsCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTestLevelsCount, GetTestLevelsCountVariables>(GetTestLevelsCountDocument, options);
        }
export function useGetTestLevelsCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTestLevelsCount, GetTestLevelsCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTestLevelsCount, GetTestLevelsCountVariables>(GetTestLevelsCountDocument, options);
        }
export type GetTestLevelsCountHookResult = ReturnType<typeof useGetTestLevelsCount>;
export type GetTestLevelsCountLazyQueryHookResult = ReturnType<typeof useGetTestLevelsCountLazyQuery>;
export type GetTestLevelsCountSuspenseQueryHookResult = ReturnType<typeof useGetTestLevelsCountSuspenseQuery>;
export type GetTestLevelsCountQueryResult = Apollo.QueryResult<GetTestLevelsCount, GetTestLevelsCountVariables>;
export const GetCheckPointsDocument = gql`
    query GetCheckPoints($where: check_points_bool_exp, $order_by: [check_points_order_by!], $offset: Int, $limit: Int) {
  check_points(where: $where, order_by: $order_by, offset: $offset, limit: $limit) {
    created_by
    teacher_id_bigint
    test_count
    updated_by
    allow_review_answers
    is_mixed
    is_practice_exam
    is_premium
    remove
    public_status
    source
    teacher_id
    type
    attempts_allowed
    diamond
    order
    time
    class_assigns
    domains
    level
    meta
    skills
    max_grade
    max_score
    description
    description_en
    image_url
    name
    name_en
    created_at
    updated_at
    id
    test_count
    test_level_id
    test_level {
      name
    }
    userCreated {
      id
      email
      phone_number
      learner_profiles {
        display_name
        id
      }
    }
    userUpdated {
      id
      email
      phone_number
      learner_profiles {
        display_name
        id
      }
    }
  }
}
    `;

/**
 * __useGetCheckPoints__
 *
 * To run a query within a React component, call `useGetCheckPoints` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckPoints` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckPoints({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetCheckPoints(baseOptions?: Apollo.QueryHookOptions<GetCheckPoints, GetCheckPointsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCheckPoints, GetCheckPointsVariables>(GetCheckPointsDocument, options);
      }
export function useGetCheckPointsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCheckPoints, GetCheckPointsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCheckPoints, GetCheckPointsVariables>(GetCheckPointsDocument, options);
        }
export function useGetCheckPointsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCheckPoints, GetCheckPointsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCheckPoints, GetCheckPointsVariables>(GetCheckPointsDocument, options);
        }
export type GetCheckPointsHookResult = ReturnType<typeof useGetCheckPoints>;
export type GetCheckPointsLazyQueryHookResult = ReturnType<typeof useGetCheckPointsLazyQuery>;
export type GetCheckPointsSuspenseQueryHookResult = ReturnType<typeof useGetCheckPointsSuspenseQuery>;
export type GetCheckPointsQueryResult = Apollo.QueryResult<GetCheckPoints, GetCheckPointsVariables>;
export const GetCheckPointsCountDocument = gql`
    query GetCheckPointsCount($where: check_points_bool_exp) {
  check_points_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetCheckPointsCount__
 *
 * To run a query within a React component, call `useGetCheckPointsCount` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckPointsCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckPointsCount({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCheckPointsCount(baseOptions?: Apollo.QueryHookOptions<GetCheckPointsCount, GetCheckPointsCountVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCheckPointsCount, GetCheckPointsCountVariables>(GetCheckPointsCountDocument, options);
      }
export function useGetCheckPointsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCheckPointsCount, GetCheckPointsCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCheckPointsCount, GetCheckPointsCountVariables>(GetCheckPointsCountDocument, options);
        }
export function useGetCheckPointsCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCheckPointsCount, GetCheckPointsCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCheckPointsCount, GetCheckPointsCountVariables>(GetCheckPointsCountDocument, options);
        }
export type GetCheckPointsCountHookResult = ReturnType<typeof useGetCheckPointsCount>;
export type GetCheckPointsCountLazyQueryHookResult = ReturnType<typeof useGetCheckPointsCountLazyQuery>;
export type GetCheckPointsCountSuspenseQueryHookResult = ReturnType<typeof useGetCheckPointsCountSuspenseQuery>;
export type GetCheckPointsCountQueryResult = Apollo.QueryResult<GetCheckPointsCount, GetCheckPointsCountVariables>;
export const GetPartExamDocument = gql`
    query GetPartExam($where: questions_bool_exp, $order_by: [questions_order_by!], $offset: Int, $limit: Int) {
  questions(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
    id
    name
    question_type
    exam_type
    time
    created_at
    updated_at
    title
    userCreated {
      id
      email
      phone_number
      learner_profiles {
        display_name
        id
      }
    }
    userUpdated {
      id
      email
      phone_number
      learner_profiles {
        display_name
        id
      }
    }
  }
}
    `;

/**
 * __useGetPartExam__
 *
 * To run a query within a React component, call `useGetPartExam` and pass it any options that fit your needs.
 * When your component renders, `useGetPartExam` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartExam({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetPartExam(baseOptions?: Apollo.QueryHookOptions<GetPartExam, GetPartExamVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPartExam, GetPartExamVariables>(GetPartExamDocument, options);
      }
export function useGetPartExamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartExam, GetPartExamVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPartExam, GetPartExamVariables>(GetPartExamDocument, options);
        }
export function useGetPartExamSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPartExam, GetPartExamVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPartExam, GetPartExamVariables>(GetPartExamDocument, options);
        }
export type GetPartExamHookResult = ReturnType<typeof useGetPartExam>;
export type GetPartExamLazyQueryHookResult = ReturnType<typeof useGetPartExamLazyQuery>;
export type GetPartExamSuspenseQueryHookResult = ReturnType<typeof useGetPartExamSuspenseQuery>;
export type GetPartExamQueryResult = Apollo.QueryResult<GetPartExam, GetPartExamVariables>;
export const GetPartExamCountDocument = gql`
    query GetPartExamCount($where: questions_bool_exp) {
  questions_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetPartExamCount__
 *
 * To run a query within a React component, call `useGetPartExamCount` and pass it any options that fit your needs.
 * When your component renders, `useGetPartExamCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartExamCount({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPartExamCount(baseOptions?: Apollo.QueryHookOptions<GetPartExamCount, GetPartExamCountVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPartExamCount, GetPartExamCountVariables>(GetPartExamCountDocument, options);
      }
export function useGetPartExamCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartExamCount, GetPartExamCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPartExamCount, GetPartExamCountVariables>(GetPartExamCountDocument, options);
        }
export function useGetPartExamCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPartExamCount, GetPartExamCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPartExamCount, GetPartExamCountVariables>(GetPartExamCountDocument, options);
        }
export type GetPartExamCountHookResult = ReturnType<typeof useGetPartExamCount>;
export type GetPartExamCountLazyQueryHookResult = ReturnType<typeof useGetPartExamCountLazyQuery>;
export type GetPartExamCountSuspenseQueryHookResult = ReturnType<typeof useGetPartExamCountSuspenseQuery>;
export type GetPartExamCountQueryResult = Apollo.QueryResult<GetPartExamCount, GetPartExamCountVariables>;
export const GetAdvicesDocument = gql`
    query GetAdvices($where: advices_bool_exp, $order_by: [advices_order_by!], $offset: Int, $limit: Int) {
  advices(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
    id
    learner_profile_id
    advice_type
    display_name
    note_admin
    phone_number
    status
    created_at
    updated_at
    learner_profile {
      id
      display_name
    }
  }
  advices_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAdvices__
 *
 * To run a query within a React component, call `useGetAdvices` and pass it any options that fit your needs.
 * When your component renders, `useGetAdvices` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdvices({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAdvices(baseOptions?: Apollo.QueryHookOptions<GetAdvices, GetAdvicesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdvices, GetAdvicesVariables>(GetAdvicesDocument, options);
      }
export function useGetAdvicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdvices, GetAdvicesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdvices, GetAdvicesVariables>(GetAdvicesDocument, options);
        }
export function useGetAdvicesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAdvices, GetAdvicesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAdvices, GetAdvicesVariables>(GetAdvicesDocument, options);
        }
export type GetAdvicesHookResult = ReturnType<typeof useGetAdvices>;
export type GetAdvicesLazyQueryHookResult = ReturnType<typeof useGetAdvicesLazyQuery>;
export type GetAdvicesSuspenseQueryHookResult = ReturnType<typeof useGetAdvicesSuspenseQuery>;
export type GetAdvicesQueryResult = Apollo.QueryResult<GetAdvices, GetAdvicesVariables>;
export const GetUnCompletedTestsRateByDayDocument = gql`
    query GetUnCompletedTestsRateByDay($where: un_completed_tests_rate_by_day_bool_exp) {
  un_completed_tests_rate_by_day(where: $where) {
    date
    total_un_completed_tests
    total_mobile_un_completed_tests
    total_practice_mode
    total_un_completed_tests_exam
    total_un_completed_tests_mini
  }
}
    `;

/**
 * __useGetUnCompletedTestsRateByDay__
 *
 * To run a query within a React component, call `useGetUnCompletedTestsRateByDay` and pass it any options that fit your needs.
 * When your component renders, `useGetUnCompletedTestsRateByDay` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnCompletedTestsRateByDay({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUnCompletedTestsRateByDay(baseOptions?: Apollo.QueryHookOptions<GetUnCompletedTestsRateByDay, GetUnCompletedTestsRateByDayVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnCompletedTestsRateByDay, GetUnCompletedTestsRateByDayVariables>(GetUnCompletedTestsRateByDayDocument, options);
      }
export function useGetUnCompletedTestsRateByDayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnCompletedTestsRateByDay, GetUnCompletedTestsRateByDayVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnCompletedTestsRateByDay, GetUnCompletedTestsRateByDayVariables>(GetUnCompletedTestsRateByDayDocument, options);
        }
export function useGetUnCompletedTestsRateByDaySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUnCompletedTestsRateByDay, GetUnCompletedTestsRateByDayVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUnCompletedTestsRateByDay, GetUnCompletedTestsRateByDayVariables>(GetUnCompletedTestsRateByDayDocument, options);
        }
export type GetUnCompletedTestsRateByDayHookResult = ReturnType<typeof useGetUnCompletedTestsRateByDay>;
export type GetUnCompletedTestsRateByDayLazyQueryHookResult = ReturnType<typeof useGetUnCompletedTestsRateByDayLazyQuery>;
export type GetUnCompletedTestsRateByDaySuspenseQueryHookResult = ReturnType<typeof useGetUnCompletedTestsRateByDaySuspenseQuery>;
export type GetUnCompletedTestsRateByDayQueryResult = Apollo.QueryResult<GetUnCompletedTestsRateByDay, GetUnCompletedTestsRateByDayVariables>;
export const GetUnCompletedTestsRateByWeekDocument = gql`
    query GetUnCompletedTestsRateByWeek($where: un_completed_tests_rate_by_week_bool_exp) {
  un_completed_tests_rate_by_week(where: $where) {
    date
    total_un_completed_tests
    total_mobile_un_completed_tests
    total_practice_mode
    total_un_completed_tests_exam
    total_un_completed_tests_mini
  }
}
    `;

/**
 * __useGetUnCompletedTestsRateByWeek__
 *
 * To run a query within a React component, call `useGetUnCompletedTestsRateByWeek` and pass it any options that fit your needs.
 * When your component renders, `useGetUnCompletedTestsRateByWeek` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnCompletedTestsRateByWeek({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUnCompletedTestsRateByWeek(baseOptions?: Apollo.QueryHookOptions<GetUnCompletedTestsRateByWeek, GetUnCompletedTestsRateByWeekVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnCompletedTestsRateByWeek, GetUnCompletedTestsRateByWeekVariables>(GetUnCompletedTestsRateByWeekDocument, options);
      }
export function useGetUnCompletedTestsRateByWeekLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnCompletedTestsRateByWeek, GetUnCompletedTestsRateByWeekVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnCompletedTestsRateByWeek, GetUnCompletedTestsRateByWeekVariables>(GetUnCompletedTestsRateByWeekDocument, options);
        }
export function useGetUnCompletedTestsRateByWeekSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUnCompletedTestsRateByWeek, GetUnCompletedTestsRateByWeekVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUnCompletedTestsRateByWeek, GetUnCompletedTestsRateByWeekVariables>(GetUnCompletedTestsRateByWeekDocument, options);
        }
export type GetUnCompletedTestsRateByWeekHookResult = ReturnType<typeof useGetUnCompletedTestsRateByWeek>;
export type GetUnCompletedTestsRateByWeekLazyQueryHookResult = ReturnType<typeof useGetUnCompletedTestsRateByWeekLazyQuery>;
export type GetUnCompletedTestsRateByWeekSuspenseQueryHookResult = ReturnType<typeof useGetUnCompletedTestsRateByWeekSuspenseQuery>;
export type GetUnCompletedTestsRateByWeekQueryResult = Apollo.QueryResult<GetUnCompletedTestsRateByWeek, GetUnCompletedTestsRateByWeekVariables>;
export const GetUnCompletedTestsRateByMonthDocument = gql`
    query GetUnCompletedTestsRateByMonth($where: un_completed_tests_rate_by_month_bool_exp) {
  un_completed_tests_rate_by_month(where: $where) {
    date
    total_un_completed_tests
    total_mobile_un_completed_tests
    total_practice_mode
    total_un_completed_tests_exam
    total_un_completed_tests_mini
  }
}
    `;

/**
 * __useGetUnCompletedTestsRateByMonth__
 *
 * To run a query within a React component, call `useGetUnCompletedTestsRateByMonth` and pass it any options that fit your needs.
 * When your component renders, `useGetUnCompletedTestsRateByMonth` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnCompletedTestsRateByMonth({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUnCompletedTestsRateByMonth(baseOptions?: Apollo.QueryHookOptions<GetUnCompletedTestsRateByMonth, GetUnCompletedTestsRateByMonthVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnCompletedTestsRateByMonth, GetUnCompletedTestsRateByMonthVariables>(GetUnCompletedTestsRateByMonthDocument, options);
      }
export function useGetUnCompletedTestsRateByMonthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnCompletedTestsRateByMonth, GetUnCompletedTestsRateByMonthVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnCompletedTestsRateByMonth, GetUnCompletedTestsRateByMonthVariables>(GetUnCompletedTestsRateByMonthDocument, options);
        }
export function useGetUnCompletedTestsRateByMonthSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUnCompletedTestsRateByMonth, GetUnCompletedTestsRateByMonthVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUnCompletedTestsRateByMonth, GetUnCompletedTestsRateByMonthVariables>(GetUnCompletedTestsRateByMonthDocument, options);
        }
export type GetUnCompletedTestsRateByMonthHookResult = ReturnType<typeof useGetUnCompletedTestsRateByMonth>;
export type GetUnCompletedTestsRateByMonthLazyQueryHookResult = ReturnType<typeof useGetUnCompletedTestsRateByMonthLazyQuery>;
export type GetUnCompletedTestsRateByMonthSuspenseQueryHookResult = ReturnType<typeof useGetUnCompletedTestsRateByMonthSuspenseQuery>;
export type GetUnCompletedTestsRateByMonthQueryResult = Apollo.QueryResult<GetUnCompletedTestsRateByMonth, GetUnCompletedTestsRateByMonthVariables>;
export const GetSubscriptionsDocument = gql`
    query GetSubscriptions($where: subscriptions_ent_bool_exp) {
  subscriptions_ent(where: $where) {
    id
    user_id
    package_id
    expired_at
  }
}
    `;

/**
 * __useGetSubscriptions__
 *
 * To run a query within a React component, call `useGetSubscriptions` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptions` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptions({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetSubscriptions(baseOptions?: Apollo.QueryHookOptions<GetSubscriptions, GetSubscriptionsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscriptions, GetSubscriptionsVariables>(GetSubscriptionsDocument, options);
      }
export function useGetSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptions, GetSubscriptionsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscriptions, GetSubscriptionsVariables>(GetSubscriptionsDocument, options);
        }
export function useGetSubscriptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSubscriptions, GetSubscriptionsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSubscriptions, GetSubscriptionsVariables>(GetSubscriptionsDocument, options);
        }
export type GetSubscriptionsHookResult = ReturnType<typeof useGetSubscriptions>;
export type GetSubscriptionsLazyQueryHookResult = ReturnType<typeof useGetSubscriptionsLazyQuery>;
export type GetSubscriptionsSuspenseQueryHookResult = ReturnType<typeof useGetSubscriptionsSuspenseQuery>;
export type GetSubscriptionsQueryResult = Apollo.QueryResult<GetSubscriptions, GetSubscriptionsVariables>;